import { AxiosResponse } from 'axios';
import api from '../../../../services/api';
import { User, UserStatusHistory } from '../../../../types';

import {
  escalate,
  escalateFail,
  escalateSuccess,
  fetchReport,
  fetchReportFail,
  fetchReportSuccess,
  updateReport,
  updateReportFail,
  updateReportSuccess,
  updateUserStatuses,
  updateUserStatusesFail,
  updateUserStatusesSuccess,
} from './reducer';

async function buildUserStatusesHistory(alertId: number, userStatusesResponse: AxiosResponse): Promise<UserStatusHistory[]> {

  const userStatusesHistory: UserStatusHistory[] = [];

  const userStatusesItems = userStatusesResponse.data.items;
  for (let i = 0; i < userStatusesItems.length; i++) {
    // const userId = userStatusesItems[i].userId;

    const userStatusHistory: UserStatusHistory = {
      user: userStatusesItems[i].user as User,
      statusesHistory: [userStatusesItems[i]],
    };

    // const userStatusesResponse = await api.get(
    //   `/messages/${alertId}?size=10&page=1&sort=ASC&user=${userId}`
    // );

    // userStatusHistory.statusesHistory = userStatusesResponse.data.items;
    userStatusesHistory.push(userStatusHistory);
  }

  return userStatusesHistory;
}

export function fetchReportData(alertId: number) {
  return async (dispatch: any) => {
    dispatch(fetchReport());

    try {
      const [
        alertResponse,
        reportResponse,
        userStatusesResponse,
      ] = await Promise.all([
        api.get(`/alerts/${alertId}`),
        api.get(`/report/${alertId}`),
        api.get(`/messages/${alertId}?size=100`),
      ]);

      const userStatusesHistory = await buildUserStatusesHistory(alertId, userStatusesResponse);

      const contactUserResponse = await api.get(
        `/users?id=${alertResponse.data.alert.contact}`,
      );

      const appointedContact = contactUserResponse.data.items[0];

      console.log('appointedContact:', appointedContact);

      return dispatch(
        fetchReportSuccess([
          { ...alertResponse.data.alert, groups: alertResponse.data.groups },
          reportResponse.data,
          userStatusesHistory,
          appointedContact,
        ]),
      );
    } catch (error) {
      return dispatch(fetchReportFail(error.message));
    }
  };
}

export function updateReportData(alertId: number) {
  return async (dispatch: any) => {
    dispatch(updateReport());

    try {
      const reportResponse = await api.get(`/report/${alertId}`);
      console.log('updateReportData ', alertId);
      console.log('reportResponse:', reportResponse);
      return dispatch(updateReportSuccess(reportResponse.data));
    } catch (error) {
      return dispatch(updateReportFail(error.message));
    }
  };
}

export function updateUserStatusesData(alertId: number) {
  return async (dispatch: any) => {
    dispatch(updateUserStatuses());

    try {
      console.log('updateUserStatusesData ', alertId);
      const userStatusesResponse = await api.get(`/messages/${alertId}?size=100`);
      console.log('userStatusesResponse:', userStatusesResponse);
      const userStatusesHistory = await buildUserStatusesHistory(alertId, userStatusesResponse);
      console.log('userStatusesHistory:', userStatusesHistory);
      return dispatch(updateUserStatusesSuccess(userStatusesHistory));
    } catch (error) {
      console.log('updateUserStatusesData error:', error.message);
      return dispatch(updateUserStatusesFail(error.message));
    }
  };
}

export function escalateForUser(alertId: number, userId: string) {
  return async (dispatch: any) => {
    dispatch(escalate());

    try {
      await api.put(`/messages/${alertId}/escalate`, { userId });

      return dispatch(escalateSuccess());
    } catch (error) {
      return dispatch(escalateFail(error.message));
    }
  };
}
