import React, {
  ReactElement,
  Ref,
  useImperativeHandle,
  FormEvent,
} from 'react';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';

export interface FormProps extends FormComponentProps {
  children: React.ReactNode;
  onError?: (error: unknown) => void;
  /**
   * If there are no validation errors, this handler will be called
   */
  onValidFieldsSubmit?: (values: unknown) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface FormContextType<T = any> {
  getFieldDecorator: WrappedFormUtils['getFieldDecorator'];
  getFieldsValue(fieldNames?: (keyof T)[]): T;
  setFieldsValue(obj: Partial<T>): void;
}

const defaultValue: FormContextType = {
  getFieldDecorator: () => () => null,
  getFieldsValue: () => ({}),
  setFieldsValue: () => {},
};
export const FormContext = React.createContext(defaultValue);

const FormWrapper = React.forwardRef(
  (props: FormProps, ref: Ref<WrappedFormUtils>): ReactElement => {
    const {
      children,
      form,
      onError,
      onValidFieldsSubmit,
      ...formProps
    } = props;

    useImperativeHandle(ref, () => form);

    const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
      e.preventDefault();

      form.validateFields((error, fieldsValue) => {
        if (error) {
          if (onError) onError(error);
        } else if (onValidFieldsSubmit) {
          onValidFieldsSubmit(fieldsValue);
        }
      });
    };

    return (
      <FormContext.Provider value={form}>
        <Form {...formProps} onSubmit={handleSubmit}>
          {children}
        </Form>
      </FormContext.Provider>
    );
  },
);

export default Form.create<FormProps>()(FormWrapper);
