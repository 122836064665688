import api from '../../services/api';

import {
  fetchReports,
  fetchReportsFail,
  fetchReportsSuccess,
  fetchReport,
  fetchReportFail,
  fetchReportSuccess,
} from './reducer';

export function fetchAllReports() {
  return async (dispatch: any) => {
    dispatch(fetchReports());

    try {
      const response = await api.get('/alerts');
      return dispatch(fetchReportsSuccess(response.data.items));
    } catch (error) {
      return dispatch(fetchReportsFail(error.message));
    }
  };
}

export function fetchReportData(alertId: number) {
  return async (dispatch: any) => {
    dispatch(fetchReport());

    try {
      const response = await api.get(`/report/${alertId}`);
      return dispatch(fetchReportSuccess(response.data));
    } catch (error) {
      return dispatch(fetchReportFail(error.message));
    }
  };
}
