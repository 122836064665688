import { createSlice, PayloadAction } from 'redux-starter-kit';
import orderBy from 'lodash.orderby';

import { AlertResponse, Report } from '../../types';

interface InitialState {
  currentReport?: Report;
  error: string;
  isLoading: boolean;
  reports: AlertResponse[];
  selectedAlertId?: number;
}

const initialState: InitialState = {
  error: '',
  isLoading: false,
  reports: [],
};

const reportsSlice = createSlice({
  slice: 'dashboard',
  initialState: initialState,
  reducers: {
    fetchReports(state) {
      state.isLoading = true;
    },
    fetchReportsSuccess(state, action: PayloadAction<AlertResponse[]>) {
      const reports = orderBy(
        action.payload.filter(report => report.status <= 1),
        report => report.starting,
        'desc',
      );

      state = {
        ...state,
        reports,
        isLoading: false,
        selectedAlertId: reports.length && reports[0].id,
      };

      return state;
    },
    fetchReportsFail(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    fetchReport() {
    },
    fetchReportSuccess(state, action: PayloadAction<Report>) {

      state.currentReport = action.payload;

      return state;
    },
    fetchReportFail(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    selectAlert(state, action: PayloadAction<number>) {

      state.selectedAlertId = action.payload;

      return state;
    },
  },
});

const { actions, reducer, selectors } = reportsSlice;

export const {
  fetchReports,
  fetchReportsFail,
  fetchReportsSuccess,
  fetchReport,
  fetchReportFail,
  fetchReportSuccess,
  selectAlert,
} = actions;
export const { getDashboard } = selectors;
export default reducer;
