import React, { useEffect, useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { List, Spin, Pagination } from 'antd';

import { fetchAllReports } from './actions';
import { getReports } from './reducer';
import { getStatusInfo } from './components/Report/Report';
import { AlertResponse } from '../../types';

import './style.scss';
import { useSearchFilter } from '../../hooks/useSearchFilter';
import { SearchInput } from '../SearchInput/SearchInput';
import { AlertReportItem } from '../AlertReportItem';

import debounce from 'lodash/debounce';

export interface ReportsProps extends RouteComponentProps {
  fetchAllReports: Function;
  isLoadingReports: boolean;
  reports: AlertResponse[];
}

const pageSize = 10;
const debounceIntervalMs = 500;

const Reports = ({
  fetchAllReports,
  history,
  reports,
  isLoadingReports,
}: ReportsProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const filteredReports = useSearchFilter(
    searchTerm,
    reports,
    report => report.title,
  );

  const reportsPage = useMemo(() => {
    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;
    return filteredReports.slice(start, end);
  }, [filteredReports, currentPage]);

  useEffect(() => {
    fetchAllReports();
  }, [fetchAllReports]);

  const csvData = [
    ['Message', 'Status', 'Sent On', 'Severity'].join('\t'),
    ...reports.map(x =>
      [x.message, getStatusInfo(x.status), x.starting, x.severity].join('\t'),
    ),
  ].join('\n');


  const onSearchChange = useMemo(
    () => debounce((value: string) => {
      setSearchTerm(value);
      setCurrentPage(1);
    }, debounceIntervalMs),
    [],
  );

  return (
    <>
      <Helmet title="Secure alerts | Reports" />
      <div className="reports-header-container">
        <h2 className="header-title">REPORTS</h2>
        <SearchInput
          disabled={isLoadingReports}
          placeholder="Find Report"
          onChange={onSearchChange}
        />
      </div>
      <div className="reports-list-container">
        {isLoadingReports ? (
          <div className="spin-container">
            <Spin size="large" tip="Fetching reports" />
          </div>
        ) : (
          <List
            header={
              <a
                data-uie-name="do-export-reports"
                href={encodeURI(`data:text/csv;charset=utf-8, ${csvData}`)}
                download="export.csv"
              >
                Export to CSV
              </a>
            }
            bordered={false}
            dataSource={reportsPage}
            renderItem={report => (
              <AlertReportItem
                alertResponseId={report.id}
                onViewReport={id => history.push(`/report/${id}`)}
                title={report.title}
                severity={report.severity}
                responsesCount={report.responses.length}
                starting={report.starting}
                status={report.status}
              />
            )}
          />
        )}
      </div>
      {!isLoadingReports && (
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          onChange={page => setCurrentPage(page)}
          total={filteredReports.length}
          size="small"
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  reports: getReports(state).reports,
  isLoadingReports: getReports(state).isLoading,
});

export default connect(mapStateToProps, { fetchAllReports })(Reports);
