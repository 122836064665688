import api from './services/api';
import {
  fetchUsers,
  fetchUsersFail,
  fetchUsersSuccess,
  uploadAttachment,
  uploadAttachmentSuccess,
  uploadAttachmentFail,
} from './reducer';

export function fetchUsersForQuery(query: string) {
  return async (dispatch: any) => {
    dispatch(fetchUsers());

    try {
      const response = await api.get(`/search?q=${query}&scope=1-1`);

      const users = response.data.conversations;

      return dispatch(fetchUsersSuccess(users));
    } catch (error) {
      return dispatch(fetchUsersFail(error.message));
    }
  };
}

export function uploadAttachmentData(data: {
  id: number;
  filename: string;
  mimeType: string;
  data: string;
}) {
  return async (dispatch: any) => {
    dispatch(uploadAttachment());

    try {
      const response = await api.post(`/attachments`, data);
      return dispatch(uploadAttachmentSuccess(response.data));
    } catch (error) {
      return dispatch(uploadAttachmentFail(error.message));
    }
  };
}
