import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Button, Icon, Input } from 'antd';

import { FormContext } from '../Form';
import { FormItem } from '../FormItem';

export interface DynamicFormItemsProps {
  dataUieName?: string;
  errorMessage: string;
  id: string;
  inputPlaceholder?: string;
  initialValues: any[];
  label: string;
  values?: any[];
  formItemsLimit?: number;
}

const getInitialValues = (id: string, initialValues: any[]) => {
  let counter: number = 0;
  return initialValues.reduce(
    (acc, value) => ({ ...acc, [`${id}_${counter++}`]: value }),
    {},
  );
};

const DynamicFormItems = ({
  dataUieName,
  errorMessage,
  id,
  inputPlaceholder,
  initialValues,
  label,
  values,
  formItemsLimit,
}: DynamicFormItemsProps) => {
  const [keys, setKeys] = useState<number[]>(
    Array.from(Array(initialValues.length).keys()),
  );
  const [initialized, setInitialized] = useState(false);
  const form = useContext(FormContext);

  const formItemsLimitReached = useMemo(
    () => (formItemsLimit ? keys.length === formItemsLimit : false),
    [formItemsLimit, keys],
  );

  useEffect(() => {
    if (form && !initialized) {
      form.setFieldsValue(getInitialValues(id, initialValues));
      setInitialized(true);
    }
  }, [form, id, initialValues, initialized]);

  useEffect(() => {
    if (form && values) {
      form.setFieldsValue(getInitialValues(id, values));

      setKeys(Array.from(Array(values.length).keys()));
    }
  }, [form, id, initialValues.length, values]);

  const remove = (key: number) => {
    setKeys(keys.filter((currentKey: number) => currentKey !== key));
  };

  const add = useCallback(() => {
    if (keys.length === 0) setKeys([0]);
    else setKeys([...keys, keys[keys.length - 1] + 1]);
  }, [keys]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.stopPropagation();
        e.preventDefault();

        if (!formItemsLimitReached) {
          add();
        }
      }
    },
    [add, formItemsLimitReached],
  );

  const formItems = keys.map((key: number, index: number) => (
    <FormItem
      id={`${id}_${key}`}
      key={key}
      options={{
        rules: [
          {
            required: true,
            type: 'string',
            message: errorMessage,
          },
        ],
      }}
    >
      <Input
        data-uie-name={`enter-user-response-${key}`}
        placeholder={inputPlaceholder}
        style={{ marginRight: 5, width: '80%' }}
        disabled={!!values}
        onKeyDown={onKeyDown}
        autoFocus={index + 1 === keys.length}
      />
      {!values ? (
        <Icon
          data-uie-name="do-remove-user-response"
          className="dynamic-delete-button"
          type="minus-circle-o"
          onClick={() => remove(key)}
        />
      ) : null}
    </FormItem>
  ));

  return (
    <>
      <div className="ant-form-item">
        <div className="ant-form-item-label">
          <label title={label}>{label}</label>
        </div>
      </div>
      <div data-uie-name={dataUieName}>{formItems}</div>
      {!values && !formItemsLimitReached ? (
        <Button
          data-uie-name="do-remove-user-response"
          type="dashed"
          onClick={add}
        >
          <Icon type="plus" /> Add field
        </Button>
      ) : null}
    </>
  );
};

export default DynamicFormItems;
