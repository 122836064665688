import { createSlice, PayloadAction } from 'redux-starter-kit';

import {
  AlertResponse,
  Report,
  User,
  UserStatusHistory,
} from '../../../../types';

interface InitialState {
  alert: Partial<AlertResponse>;
  error: string;
  isLoading: boolean;
  report: Partial<Report>;
  userStatusesHistory: UserStatusHistory[];
  appointedContact: Partial<User>;
}

const initialState: InitialState = {
  alert: {},
  error: '',
  isLoading: false,
  report: {},
  userStatusesHistory: [],
  appointedContact: {},
};

const reportSlice = createSlice({
  slice: 'report',
  initialState: initialState,
  reducers: {
    escalate() { },
    escalateSuccess() { },
    escalateFail(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    fetchReport(state) {
      state.isLoading = true;
      state.report = {};
      state.userStatusesHistory = [];
      state.appointedContact = {};
      state.alert = {};
    },
    fetchReportSuccess(
      state,
      action: PayloadAction<[AlertResponse, Report, UserStatusHistory[], User]>,
    ) {
      const [alert, report, userStatusesHistory, appointedContact] = action.payload;

      state.isLoading = false;
      state.alert = alert;
      state.report = report;
      state.userStatusesHistory = userStatusesHistory;
      state.appointedContact = appointedContact;

    },
    fetchReportFail(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateReport(state) { },
    updateReportSuccess(state, action: PayloadAction<Report>) {
      state.report = action.payload;

      return state;
    },
    updateReportFail(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    updateUserStatuses(state) { },
    updateUserStatusesSuccess(
      state,
      action: PayloadAction<UserStatusHistory[]>,
    ) {
      state.userStatusesHistory = action.payload;
    },
    updateUserStatusesFail(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

const { actions, reducer, selectors } = reportSlice;

export const {
  escalate,
  escalateFail,
  escalateSuccess,
  fetchReport,
  fetchReportFail,
  fetchReportSuccess,
  updateReport,
  updateReportFail,
  updateReportSuccess,
  updateUserStatuses,
  updateUserStatusesFail,
  updateUserStatusesSuccess,
} = actions;
export const { getReport } = selectors;
export default reducer;
