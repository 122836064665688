import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Divider, Modal, Pagination, Spin, notification } from 'antd';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import Template from './components/Template';
import { fetchAllTemplates, removeTemplate, sendTemplate } from './actions';
import { getTemplates, sendTemplateAlertClearError } from './reducer';
import { Template as TemplateType } from './types';

import './style.scss';
import { useSearchFilter } from '../../hooks/useSearchFilter';
import { SearchInput } from '../SearchInput/SearchInput';

import debounce from 'lodash/debounce';

export interface TemplatesProps {
  title?: React.ReactNode;
  canDelete?: boolean;
  fetchAllTemplates: Function;
  removeTemplate: Function;
  templates: TemplateType[];
  isLoadingTemplates: boolean;
  sendTemplate: Function;
  sendingTemplates: Record<number, boolean>;
  sendingTemplatesErrors: Record<number, string>;
  sendTemplateAlertClearError: Function;
}

const { confirm } = Modal;

const pageSize = 10;
const debounceIntervalMs = 500;

const Templates = ({
  canDelete = true,
  fetchAllTemplates,
  removeTemplate,
  templates,
  title,
  isLoadingTemplates,
  sendingTemplates,
  sendingTemplatesErrors,
  sendTemplate,
  sendTemplateAlertClearError,
}: TemplatesProps) => {
  const history = useHistory();

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const filteredTemplates = useSearchFilter(
    searchTerm,
    templates,
    template => template.title,
  );

  const reportsPage = useMemo(() => {
    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;
    return filteredTemplates.slice(start, end);
  }, [filteredTemplates, currentPage]);

  useEffect(() => {
    fetchAllTemplates();
  }, [fetchAllTemplates]);

  const onSearchChange = useMemo(
    () => debounce((value: string) => {
      setSearchTerm(value);
      setCurrentPage(1);
    }, debounceIntervalMs),
    [],
  );

  const handleRemove = useCallback(
    async (templateId: number) => {
      confirm({
        title: 'Remove Template',
        content: (
          <>
            {'Are you sure you want to remove template?'}
            <br />
            {
              'All Alerts with current templates will stay unaffected with this removal.'
            }
          </>
        ),
        async onOk() {
          await removeTemplate(templateId);
          await fetchAllTemplates();
        },
      });
    },
    [fetchAllTemplates, removeTemplate],
  );

  const onSendNow = (template: TemplateType) => {
    sendTemplate(template);
  };

  if (Object.keys(sendingTemplatesErrors).length > 0) {
    Object.keys(sendingTemplatesErrors).forEach(id => notification.close(id));

    Object.keys(sendingTemplatesErrors).forEach(id => {
      notification.error({
        key: id,
        message: 'An error occurred during sending of alert',
        description: sendingTemplatesErrors[+id],
        duration: 0,
        onClose: () => sendTemplateAlertClearError(+id),
        className: 'alert-sending-error-notification',
      });
    });
  }

  return (
    <>
      <Helmet title="Secure alerts | Templates" />
      <div className="templates-header-container">
        <h2 className="header-title">TEMPLATES</h2>
        <SearchInput
          placeholder="Find Template"
          disabled={isLoadingTemplates}
          onChange={onSearchChange}
        />
      </div>
      <div className="templates-list-container">
        {isLoadingTemplates ? (
          <div className="spin-container">
            <Spin size="large" tip="Fetching templates" />
          </div>
        ) : (
          <>
            <Divider />
            <div>
              {reportsPage.map(template => (
                <Template
                  {...template}
                  key={template.id}
                  canDelete={canDelete}
                  onEdit={template => history.push('/createAlert', template)}
                  onRemove={handleRemove}
                  onSendNow={onSendNow}
                  isSending={sendingTemplates[template.id]}
                />
              ))}
            </div>
          </>
        )}
      </div>
      {!isLoadingTemplates && (
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          onChange={page => setCurrentPage(page)}
          total={filteredTemplates.length}
          size="small"
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  templates: getTemplates(state).templates,
  isLoadingTemplates: getTemplates(state).isLoading,
  sendingTemplates: getTemplates(state).sendingTemplates,
  sendingTemplatesErrors: getTemplates(state).sendingTemplatesErrors,
});

export default connect(mapStateToProps, {
  fetchAllTemplates,
  removeTemplate,
  sendTemplate,
  sendTemplateAlertClearError,
})(Templates);
