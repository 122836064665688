import { Tag } from 'antd';
import React from 'react';
import { UserStatus } from '../../../../types';
import moment from 'moment';
import WhiteButton from '../../../WhiteButton';

import { InfoIcon } from '../../../InfoIcon';
import { HelpCircle } from 'react-ionicons';

import './style.scss';

interface ReportUserStatusInfoProps {
  userStatus: UserStatus;
  onEscalate: (alertId: number, userId: string) => void;
}

export const ReportUserStatusInfo: React.FC<ReportUserStatusInfoProps> = ({
  userStatus,
  onEscalate,
}) => {
  const { user, created, response, status, escalated, alertId } = userStatus;

  let action: React.ReactNode = null;
  if (escalated === 1 && user.phone) {
    action = (
      <div data-uie-name="flag-escalate" className="custom-message-escalated">
        <HelpCircle width="14px" height="14px" color="currentColor" />
      </div>
    );
  }

  if (status !== 'RESPONDED' && user.phone) {
    action = (
      <WhiteButton
        data-uie-name="do-escalate"
        className="escalate-button"
        onClick={() => onEscalate(alertId, user.userId)}
      >
        Escalate
      </WhiteButton>
    );
  }

  return (
    <div className="report-user-status-info-row">
      <div className="user-col">
        <span
          data-uie-name="list-item-user-name"
          className="user-full-name"
          style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}
        >
          <span>
            {user.name}
          </span>
          <InfoIcon type="wire" />
          {user.phone && <InfoIcon type="sms" />}
        </span>
        <span data-uie-name="list-item-user-title" className="user-title">
          {''}
        </span>
        <br />
        <span
          data-uie-name="list-item-user-department"
          className="user-department"
        >
          {''}
        </span>
        <span data-uie-name="list-item-user-location" className="user-location">
          {''}
        </span>
      </div>
      <div className="message-status-col">
        <Tag>{status}</Tag>
        <div data-uie-name="list-item-message-age" className="msg-age">
          {moment.utc(created).local().fromNow()}
        </div>
        {response ? (
          <div className="user-response">
            <span
              data-uie-name="list-item-message-response"
              className="arrows-right"
            ></span>
            {response}
          </div>
        ) : null}
      </div>
      <div className="action-col">{action}</div>
    </div>
  );
};
