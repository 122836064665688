import React from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import './SearchInput.scss';

interface SearchInputProps {
  placeholder: string;
  disabled?: boolean;
  onChange: (value: string) => void;
}

export const SearchInput = ({ placeholder, onChange, disabled = false }: SearchInputProps) => (
  <Input
    className="search-input"
    prefix={<SearchOutlined />}
    placeholder={placeholder}
    disabled={disabled}
    onChange={e => onChange(e.target.value)}
  />
);
