import { combineReducers, createSlice, PayloadAction } from 'redux-starter-kit';

import dashboardReducer from './components/Dashboard/reducer';
import reportsReducer from './components/Reports/reducer';
import reportReducer from './components/Reports/components/Report/reducer';
import templatesReducer from './components/Templates/reducer';
import { Attachment, Group, SearchType, SortOrder, User } from './types';
import { AccessTokenData } from '@wireapp/api-client/src/auth';

interface InitialState {
  accessToken?: AccessTokenData;
  attachment?: Attachment;
  locations: Group[];
  page: number;
  pageSize: number;
  query: string;
  sortOrder: SortOrder;
  searchType?: SearchType;
  users: User[];
  usersGroups: (User | Group)[];
  usersGroupsCached: (User | Group)[];
  appTitle: string;
  logoUrl: string;
  isFetchingLocations: boolean;
}

const initialState: InitialState = {
  locations: [],
  page: 1,
  pageSize: 12,
  query: '',
  searchType: 'DEPARTMENT',
  sortOrder: 'ASC',
  users: [],
  usersGroups: [],
  usersGroupsCached: [],
  appTitle: '',
  logoUrl: '',
  isFetchingLocations: false,
};

const appSlice = createSlice({
  slice: 'app',
  initialState: initialState,
  reducers: {
    setAccessToken(state, action: PayloadAction<AccessTokenData>) {
      state.accessToken = action.payload;
      return state;
    },
    searchLocations(state, action: PayloadAction<string>) {
      state.isFetchingLocations = true;
      return state;
    },
    searchLocationsSuccess(state, action: PayloadAction<Group[]>) {
      state.locations = action.payload;
      state.isFetchingLocations = false;
      return state;
    },
    searchLocationsFail(state, action: PayloadAction<string>) {
      state.isFetchingLocations = false;
    },
    fetchUsers(state) {
    },
    fetchUsersSuccess(state, action: PayloadAction<User[]>) {
      state.users = action.payload;
      return state;
    },
    fetchUsersFail(state, action: PayloadAction<string>) {
    },
    fetchUsersGroups(state) {
    },
    fetchUsersGroupsSuccess(state, action: PayloadAction<(User | Group)[]>) {
      state.usersGroups = action.payload;
    },
    fetchUsersGroupsFail(state, action: PayloadAction<string>) {
    },
    appendToUsersGroupsCached(state, action: PayloadAction<{ users: User[], groups: Group[] }>) {
    },
    setUsersGroupsCached(state, action: PayloadAction<(User | Group)[]>) {
      state.usersGroupsCached = action.payload;
    },
    nextPage(state) {
      state.page += 1;

      return state;
    },
    previousPage(state) {
      state.page -= 1;

      return state;
    },
    setRowsPerPage(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
      state.page = 1;

      return state;
    },
    setQuery(state, action: PayloadAction<string>) {
      state.query = action.payload;
      state.page = 1;

      return state;
    },
    setSearchType(state, action: PayloadAction<SearchType | undefined>) {
      state.page = 1;
      state.searchType = action.payload;

      return state;
    },
    toggleUsersGroupsSort(state) {
      state.sortOrder = state.sortOrder === 'ASC' ? 'DESC' : 'ASC';

      return state;
    },
    uploadAttachment(state) {
    },
    uploadAttachmentSuccess(state, { payload }: PayloadAction<Attachment>) {
      state.attachment = payload;

      return state;
    },
    uploadAttachmentFail(state, action: PayloadAction<string>) {
    },
    fetchConfig() { },
    fetchConfigSuccess(
      state,
      action: PayloadAction<{ name: string; logo: string }>,
    ) {
      const { name, logo } = action.payload;

      state.appTitle = name;
      state.logoUrl = logo;

      return state;
    },
  },
});

const { actions, reducer, selectors } = appSlice;

export const {
  setAccessToken,
  searchLocations,
  searchLocationsFail,
  searchLocationsSuccess,
  fetchUsers,
  fetchUsersFail,
  fetchUsersSuccess,
  fetchUsersGroups,
  fetchUsersGroupsFail,
  fetchUsersGroupsSuccess,
  nextPage,
  setUsersGroupsCached,
  previousPage,
  setRowsPerPage,
  setQuery,
  setSearchType,
  toggleUsersGroupsSort,
  uploadAttachment,
  uploadAttachmentFail,
  uploadAttachmentSuccess,
  fetchConfig,
  fetchConfigSuccess,
  appendToUsersGroupsCached
} = actions;

export const { getApp } = selectors;

const combinedReducers = combineReducers({
  app: reducer,
  dashboard: dashboardReducer,
  reports: reportsReducer,
  report: reportReducer,
  templates: templatesReducer,
});

export type ApplicationState = ReturnType<typeof combinedReducers>;

export default combinedReducers;
