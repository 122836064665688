import { Tooltip } from 'antd';
import React from 'react';

import wireLogo from '../Icons/Wire-symbol.svg';
import smsLogo from '../Icons/phone-sms.svg';

export const InfoIcon: React.FC<{ type: 'wire' | 'sms' }> = ({ type }) => (
  <Tooltip
    title={
      type === 'wire'
        ? 'User has Wire and is connected to bot'
        : 'User has telephone number in AD'
    }
  >
    <div
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 10,
        minWidth: 20,
        maxWidth: 20,
        minHeight: 20,
        maxHeight: 20,
        border: '1px solid black',
        borderRadius: 20,
      }}
    >
      <img
        style={{ width: 12, height: 12 }}
        src={type === 'wire' ? wireLogo : smsLogo}
        alt="communication-type-logo"
      />
    </div>
  </Tooltip>
);
