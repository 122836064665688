import styled from '@emotion/styled';

const baseButtonStyle = {
  background: '#FFFFFF',
  border: '1px solid #3a3a3a',
  borderRadius: 30,
  color: '#3a3a3a',
  fontSize: 13,
  fontWeight: 700,
  height: 46,
  lineHeight: '46px',
  padding: '0 24px',
};

export default styled.button({
  ...baseButtonStyle,
  ':hover:not(:disabled)': {
    boxShadow: '0 0 6px #cacaca',
    cursor: 'pointer',
  },
  ':disabled': {
    cursor: 'not-allowed',
    opacity: 0.5,
  },
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  span: {
    display: 'inline-flex',
  },
});
