import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  RouteComponentProps,
} from 'react-router-dom';
import styled from '@emotion/styled';
import MainLayout from './components/MainLayout';

import 'antd/dist/antd.css';
import './style.scss';

const Container = styled.div`
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1480px;
  }

  @media (min-width: 576px) {
    .container {
      max-width: 540px;
    }
  }

  @media (min-width: 768px) {
    .container {
      max-width: 720px;
    }
  }

  @media (min-width: 992px) {
    .container {
      max-width: 960px;
    }
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 1140px;
    }
  }
`;

const Default = (props: RouteComponentProps) => (
  <Container>
    <MainLayout {...props} />
  </Container>
);

const App: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" component={Default} />
    </Switch>
  </BrowserRouter>
);

export default App;
