import React from 'react';
import { Typography, Divider } from 'antd';
import { Report, AlertResponse, UserStatus } from '../../../../types';
import { ReportStatisticsBarChart } from '../../../ReportStatisticsBarChart/ReportStatisticsBarChart';

const { Text } = Typography;

interface AlertStatisticsProps {
  report: Partial<Report>;
  alert: Partial<AlertResponse>;
  userStatuses: UserStatus[];
}

const sendingStatsItems: Array<keyof Partial<Report>> = [
  'scheduled',
  'sent',
  'delivered',
  'read',
  'responded',
];

const sendingStatsKeyToName: Record<string, string> = {
  scheduled: 'SCHEDULED',
  sent: 'SENT',
  delivered: 'DELIVERED',
  read: 'READ',
  responded: 'RESPONDED',
};

const StatusItem: React.FC<{ name: string; quantity?: number }> = ({
  name,
  quantity,
}) => (
  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
    <Text style={{ fontSize: 10, textAlign: 'center' }} type="secondary">
      {name}
    </Text>
    <Text style={{ fontSize: 22, fontWeight: 700, textAlign: 'center' }}>
      {quantity}
    </Text>
  </div>
);

export const AlertStatistics: React.FC<AlertStatisticsProps> = ({
  report,
  alert,
  userStatuses,
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="group-title">Alert Overview</div>
      <Divider />
      <div className="stat-content">
        {/* 
        <Col span={8}>
          <div className="section-title" style={{ marginBottom: 20 }}>
            USER ANSWERS
          </div>
          <div style={{ marginRight: 10 }}>
            {userResponseData.map(responseData => (
              <div className="user-response-bar" key={responseData.name}>
                <Progress
                  percent={(responseData.value / maxResponseCount) * 100}
                  showInfo={false}
                />
                <div className="user-response-bar-name">
                  {responseData.name}
                </div>
                <div className="user-response-bar-count">
                  {responseData.value}
                </div>
              </div>
            ))}
          </div>
        </Col>
        */}
        <div className="stat-box">
          <div
            className="section-title"
            style={{
              marginBottom: 20,
            }}
          >
            MESSAGE STATUS
          </div>
          <div>
            <ReportStatisticsBarChart
              sent={report.sent || 0}
              delivered={report.delivered || 0}
              responded={report.responded || 0}
              read={report.read || 0}
              smsSent={report.smsSent || 0}
            />
          </div>
        </div>
        <div className="stat-box">
          <Text style={{ fontSize: 12, fontWeight: 700 }}>SENDING STATS</Text>
          <div className="alert-info-box" style={{ marginTop: 10 }}>
            {sendingStatsItems.map(sendingStatItem => (
              <StatusItem
                key={sendingStatsKeyToName[sendingStatItem]}
                name={sendingStatsKeyToName[sendingStatItem]}
                quantity={report[sendingStatItem]}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
