import api from '../../services/api';
import { AlertResponse } from '../../types';

import { fetchReports, fetchReportsFail, fetchReportsSuccess } from './reducer';

export function fetchAllReports() {
  return async (dispatch: any) => {
    dispatch(fetchReports());

    const pageSize = 20;
    let currentPage = 1;
    let reports: AlertResponse[] = [];
    let fetchedReports: AlertResponse[];

    try {
      do {
        const response = await api.get(`/alerts?size=${pageSize}&page=${currentPage}`);
        fetchedReports = response.data.items;
        reports = [...reports, ...fetchedReports];
        currentPage++;
      } while (fetchedReports.length === pageSize)

      return dispatch(fetchReportsSuccess(reports));
    } catch (error) {
      return dispatch(fetchReportsFail(error.message));
    }
  };
}
