import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {
  Alert as AntAlert,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Icon,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Tabs,
} from 'antd';
import moment, { Moment } from 'moment';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { UploadFile, RcFile } from 'antd/lib/upload/interface';
import { WrappedFormUtils } from 'antd/lib/form/Form';

import {
  createAlert,
  fetchGroups,
  fetchUsersInGroup,
  fetchUsers,
} from '../../services/api';
import ExpandableCheckbox from './components/ExpandableCheckbox';
// import LocationSearch from './components/LocationSearch';
import UserGroup from './components/UserGroup';
import { Form, FormItem, DynamicFormItems } from '../Form';
import { Template as TemplateType } from '../Templates/types';
import WhiteButton from '../WhiteButton';
import { fetchUsersForQuery, uploadAttachmentData } from '../../actions';
import { saveTemplate, setTemplate } from '../../components/Templates/actions';
import {
  getApp,
  nextPage,
  previousPage,
  setRowsPerPage,
  setQuery,
  setSearchType,
  toggleUsersGroupsSort,
  appendToUsersGroupsCached,
} from '../../reducer';
import { Attachment, Group, SearchType, SortOrder, User } from '../../types';
import { useDebounce } from '../../hooks';

import './style.scss';
import { SearchOutlined } from '@ant-design/icons';
import { useUsersGroupsCount } from '../../hooks/useUsersGroupsCount';
import { ArrowForward, ChevronBack } from 'react-ionicons';

const { confirm } = Modal;

export interface CreateAlertProps {
  attachment?: Attachment;
  fetchUsersForQuery: Function;
  pageSize: number;
  searchType?: SearchType;
  nextPage: Function;
  page: number;
  previousPage: Function;
  saveTemplate(template: TemplateType): Promise<any>;
  setTemplate(template: TemplateType): Promise<any>;
  setRowsPerPage: Function;
  setQuery: Function;
  setSearchType: Function;
  sortOrder: SortOrder;
  toggleUsersGroupsSort: Function;
  uploadAttachmentData: Function;
  appendToUsersGroupsCached: Function;
  users: User[];
  usersGroups: (User | Group)[];
  usersGroupsCached: (User | Group)[];
}

interface FormFields {
  affectedLocations: string[];
  alertEndingDateAndTime?: Moment;
  alertTitle: string;
  appointedContact: string;
  attachment?: { file: RcFile; fileList: UploadFile[] };
  customMessage?: string;
  hazardLevel: number;
}

const mandatoryFieldWarning = 'This field is mandatory';
const getKeyValueForEntry = (entry: User | Group) => entry ? ('groupId' in entry ? `g_${(entry as Group).groupId}` : `${(entry as User).conversationId}`) : '';

const CreateAlert = ({
  attachment,
  fetchUsersForQuery,
  saveTemplate,
  setTemplate,
  setQuery,
  users,
  usersGroups,
  usersGroupsCached,
  appendToUsersGroupsCached,
}: CreateAlertProps) => {
  const history = useHistory();
  const { state: selectedTemplateFromState } = useLocation<
    TemplateType | undefined
  >();
  const [selectedTemplate, setSelectedTemplate] = useState(
    selectedTemplateFromState,
  );
  const [overWriteTemplate, setOverwriteTemplate] = useState(
    !!selectedTemplate,
  );
  const [templateTitle, setTemplateTitle] = useState(
    (selectedTemplate && selectedTemplate.title) || '',
  );
  const formRef = useRef<WrappedFormUtils>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [affectedLocations, setAffectedLocations] = useState<
    Group[] | undefined
  >([]);

  const [selectedUsersGroups, setSelectedUsersGroups] = useState<
    Array<User | Group>
  >([]);

  const [activeKey, setActiveKey] = useState('1');
  const [searchTerm, setSearchTerm] = useState('');
  const [sendingMode, setSendingMode] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [usersGroupsSearchTerm, setUsersGroupsSearchTerm] = useState('');
  const debouncedUsersGroupsSearchTerm = useDebounce(
    usersGroupsSearchTerm,
    500,
  );

  const totalSelectedUsers = useUsersGroupsCount(selectedUsersGroups);

  useEffect(() => {
    const fetch = async () => {
      if (!selectedTemplate) {
        return;
      }

      const templateUsersIds = selectedTemplate.users;
      const templateGroupsIds = selectedTemplate.groups;

      let templateUsers: User[] = [];
      let templateGroups: Group[] = [];

      if (templateUsersIds.length > 0) {
        for (let i = 0; i < templateUsersIds.length; i++) {
          const templateUser = usersGroups.find(u => ('conversationId' in u) && (u as User).conversationId === templateUsersIds[i]) as User;
          if (templateUser != null) {
            templateUsers.push(templateUser);
          }
        }
      }

      if (templateGroupsIds.length > 0) {
        templateGroups = await fetchGroups(templateGroupsIds);
      }

      const selectedUsersAndGroups = [...templateUsers, ...templateGroups];

      setSelectedUsersGroups(selectedUsersAndGroups);
      appendToUsersGroupsCached({
        users: templateUsers,
        groups: templateGroups,
      });
    };

    fetch();
  }, [usersGroups, selectedTemplate, appendToUsersGroupsCached]);


  const handleUsersGroupsSelectionChange = useCallback(
    (e: CheckboxValueType[]) => {
      // leave values in collection 'selectedUsersGroups' which doesn't exist in collection 'usersGroups'
      // those selected values cannot be selected or deselected as they are not even rendered
      // rest of values are determined by argument to callback function, 'e', which are selected values
      const newSelectedUsersGroups = selectedUsersGroups.filter(
        selected =>
          usersGroups.findIndex(
            x => getKeyValueForEntry(x) === getKeyValueForEntry(selected),
          ) === -1,
      );

      const newSelected = e
        .map(
          selected =>
            usersGroupsCached.find(x => getKeyValueForEntry(x) === selected) ||
            null,
        )
        .filter(x => x !== null) as Array<User | Group>;

      setSelectedUsersGroups([...newSelectedUsersGroups, ...newSelected]);
    },
    [usersGroupsCached, usersGroups, selectedUsersGroups],
  );

  const handleSelectedUsersGroupsChange = useCallback(
    (e: CheckboxValueType[]) => {
      const newSelected = e
        .map(
          selected =>
            usersGroupsCached.find(x => getKeyValueForEntry(x) === selected) ||
            null,
        )
        .filter(x => x !== null) as Array<User | Group>;

      setSelectedUsersGroups(newSelected);
    },
    [usersGroupsCached],
  );

  const handlePrevious = useCallback(() => {
    const tabIndex = Number(activeKey);

    if (tabIndex === 1) {
      history.goBack();
    } else {
      const nextActiveKey = tabIndex - 1;

      setActiveKey(String(nextActiveKey));
      window.scrollTo(0, 0);
    }
  }, [activeKey, history]);

  const handleOnSave = useCallback(
    (values: unknown) => {
      const {
        alertTitle,
        alertEndingDateAndTime,
        hazardLevel,
        appointedContact,
        customMessage,
      } = values as FormFields;

      const valuesDictionary = values as Record<string, unknown>;

      const responses = Object.keys(valuesDictionary)
        .filter(k => k.startsWith('userAnswers'))
        .map(k => valuesDictionary[k]);

      console.log("appointedContact:", appointedContact);
      console.log("users", users);

      const appointedContactUser = users.find(
        user => user.userId === appointedContact,
      );

      console.log("appointedContactUser:", appointedContactUser);

      const contact =
        (appointedContactUser && appointedContactUser.userId) || '';

      console.log("Contact", contact);

      createAlert(
        {
          contact,
          attachment: attachment && attachment.id,
          ending: alertEndingDateAndTime,
          groups: selectedUsersGroups
            .filter(s => 'groupId' in s)
            .map(v => (v as Group).groupId),
          locations: affectedLocations
            ? affectedLocations.map(({ groupId }) => groupId)
            : [],
          message: customMessage || '',
          responses: responses as string[],
          severity: hazardLevel,
          starting: moment(),
          title: alertTitle,
          conversations: selectedUsersGroups
            .filter(s => 'conversationId' in s)
            .map(v => (v as User).conversationId),
        },
        true,
      )
        .then(({ data }) => history.push(`/report/${data.alert.id}`))
        .catch(error => setErrorMessage(error.message));
    },
    [users, attachment, selectedUsersGroups, affectedLocations, history],
  );

  const handleNextConfirm = useCallback(() => {
    formRef.current &&
      formRef.current.validateFieldsAndScroll((errors, values) => {
        if (!errors) {
          const tabIndex = Number(activeKey);

          if (tabIndex < 3) {
            const nextActiveKey = tabIndex + 1;

            setActiveKey(String(nextActiveKey));
            window.scrollTo(0, 0);
          } else {
            handleOnSave(values);
          }
        }
      });
  }, [activeKey, handleOnSave]);

  const handleSaveOrUpdateTemplate = useCallback(() => {
    confirm({
      title: 'Confirm',
      content: `Are you sure you want to ${overWriteTemplate && selectedTemplate ? 'update' : 'create'
        } template?`,
      onOk() {
        if (formRef.current) {
          const formValues = formRef.current.getFieldsValue();
          const {
            appointedContact,
            customMessage,
            hazardLevel,
          } = formValues as FormFields;

          const responses = Object.keys(formValues)
            .filter(k => k.startsWith('userAnswers'))
            .map(k => formValues[k]);

          const isUpdate = overWriteTemplate && selectedTemplate;

          const template: TemplateType = {
            responses,
            attachmentId: attachment && attachment.id,
            contact: appointedContact,
            groups: selectedUsersGroups
              .filter(s => 'groupId' in s)
              .map(v => (v as Group).groupId),
            id:
              (overWriteTemplate && selectedTemplate && selectedTemplate.id) ||
              0,
            locations: affectedLocations
              ? affectedLocations.map(({ groupId }) => groupId)
              : [],
            message: customMessage || '',
            severity: hazardLevel,
            title: templateTitle,
            users: selectedUsersGroups
              .filter(s => 'conversationId' in s)
              .map(v => (v as User).conversationId),
          };

          const callback = isUpdate ? setTemplate : saveTemplate;

          callback(template).then(data => {
            notification.open({
              message: 'Success',
              description: `Template successfully ${isUpdate ? 'updated' : 'created'
                }!`,
            });

            if (isUpdate) return;

            setSelectedTemplate(data.payload);
            setOverwriteTemplate(true);
          });
        }
      },
    });
  }, [
    affectedLocations,
    attachment,
    overWriteTemplate,
    saveTemplate,
    selectedUsersGroups,
    selectedTemplate,
    setTemplate,
    templateTitle,
  ]);

  useEffect(() => {
    fetchUsersForQuery(debouncedSearchTerm);
  }, [debouncedSearchTerm, fetchUsersForQuery]);

  useEffect(() => {
    setQuery(debouncedUsersGroupsSearchTerm);
  }, [debouncedUsersGroupsSearchTerm, setQuery]);

  useEffect(() => {
    // TODO: set attachment (need to fetch it first or hide upload dialog)

    const asyncCallback = async () => {
      if (formRef.current && selectedTemplate) {
        let {
          contact,
          groups,
          message,
          severity,
          users,
          title,
        } = selectedTemplate;

        formRef.current.setFieldsValue({
          appointedContact: contact,
          customMessage: message,
          hazardLevel: severity,
          usersSearch: users,
          alertTitle: title,
        });

        if (contact) {
          console.log("contact:", contact);
          const contactUser = await fetchUsers([contact]);
          if (contactUser == null) {
            contact = '';
          }
        }

        let usersGroupsArr: Array<User | Group> = [];

        if (groups && groups.length > 0) {
          fetchGroups(groups).then(groupsLocations => {
            const { groups, locations } = groupsLocations.reduce(
              (
                acc: { groups: Group[]; locations: Group[] },
                groupLocation: Group,
              ) => {
                if (groupLocation.type === 1) {
                  return { ...acc, groups: [...acc.groups, groupLocation] };
                } else if (groupLocation.type === 2) {
                  return {
                    ...acc,
                    locations: [...acc.locations, groupLocation],
                  };
                }

                return acc;
              },
              { groups: [], locations: [] },
            );

            setAffectedLocations(locations);

            usersGroupsArr = [...usersGroupsArr, ...groups];
          });
        }

        if (users && users.length > 0) {
          const fetchedUsers = await fetchUsers(users);
          usersGroupsArr = [...usersGroupsArr, ...fetchedUsers];
        }

        setErrorMessage(undefined);
      }
    };

    asyncCallback();
  }, [fetchUsersForQuery, formRef, selectedTemplate]);

  const renderSelectedUsersGroups = () => {
    const defaultValue = selectedUsersGroups.map(getKeyValueForEntry);

    return (
      <Checkbox.Group
        onChange={handleSelectedUsersGroupsChange}
        className="selected-users-groups"
        defaultValue={defaultValue}
      >
        {selectedUsersGroups.map(selectedUserGroup =>
          'conversationId' in selectedUserGroup ? (
            <Checkbox
              key={getKeyValueForEntry(selectedUserGroup)}
              value={getKeyValueForEntry(selectedUserGroup)}
              defaultChecked
            >
              <UserGroup userGroup={selectedUserGroup} />
            </Checkbox>
          ) : (
            <ExpandableCheckbox
              fetchItems={() => fetchUsersInGroup('groupId' in selectedUserGroup ? (selectedUserGroup as Group).groupId : "")}
              filterItem={(query, item) => item.username.includes(query)}
              key={getKeyValueForEntry(selectedUserGroup)}
              renderItem={item => <UserGroup userGroup={item} />}
              style={{ display: 'block', margin: 0 }}
              value={getKeyValueForEntry(selectedUserGroup)}
              defaultChecked
            >
              <UserGroup userGroup={selectedUserGroup} />
            </ExpandableCheckbox>
          ),
        )}
      </Checkbox.Group>
    );
  };

  const renderSummary = () => {
    if (activeKey !== '3' || !formRef.current) return null;

    const formValues = formRef.current.getFieldsValue();
    const {
      alertTitle,
      appointedContact,
      customMessage,
    } = formValues as FormFields;

    const responses = Object.keys(formValues)
      .filter(k => k.startsWith('userAnswers'))
      .map(k => formValues[k]);

    const appointedContactUser = users.find(
      user => user.userId === appointedContact,
    );

    return (
      <>
        <h3>At a Glance</h3>
        <span className="subtitle">Check Secure Alert Summary and Finish</span>
        <Row gutter={30}>
          <Col span={24}>
            <div className="alert-view">
              <div className="box-top">
                <span className="label label-title">Alert Title</span>
                <h2
                  data-uie-name="label-alert-title"
                  data-uie-value={alertTitle}
                  className="summary"
                >
                  {alertTitle}
                </h2>
              </div>
              <div className="box-content">
                <div className="inner">
                  <span className="label label-title">Appointed Contact</span>
                  <p
                    data-uie-name="label-appointed-contact"
                    className="summary"
                  >
                    {appointedContactUser &&
                      `${appointedContactUser.name}`}
                  </p>
                </div>
              </div>
              <div className="box-content">
                <div className="inner-50">
                  <span className="label label-title">Alert Message</span>
                  <p
                    data-uie-name="label-alert-message"
                    data-uie-value={customMessage}
                    className="summary"
                  >
                    {customMessage}
                  </p>
                </div>
                <div className="inner-50">
                  <span className="label label-title">User Responses</span>
                  <ul
                    data-uie-name="list-user-responses"
                    className="summary-list response"
                  >
                    {responses.map(response => (
                      <li
                        data-uie-name="item-user-response"
                        data-uie-value={response}
                        key={response}
                      >
                        {response}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="divider"></div>
              <div className="box-content">
                {/* 
                <div className="inner-50">
                  <span className="label label-title">Affected Locations</span>
                  <ul
                    data-uie-name="list-affected-locations"
                    className="summary-list locations"
                  >
                    {affectedLocations &&
                      affectedLocations.map(({ name }) => (
                        <li
                          data-uie-name="item-affected-location"
                          data-uie-value={name}
                          key={name}
                        >
                          {name}{' '}
                        </li>
                      ))}
                  </ul>
                      </div> */}
                <div className="inner-50">
                  <span className="label label-title">Sending list</span>
                  <div className="sending-to-data">
                    {selectedUsersGroups.map(x =>
                      'groupId' in x ? (
                        <UserGroup key={(x as Group).groupId} userGroup={x} />
                      ) : (
                        <UserGroup key={(x as User).conversationId} userGroup={x} />
                      ),
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="divider big"></div>
      </>
    );
  };

  return (
    <>
      {errorMessage ? (
        <AntAlert
          type="error"
          banner
          closable
          showIcon
          description={errorMessage}
          message="An error occurred"
        />
      ) : null}
      <Form wrappedComponentRef={formRef}>
        <Tabs activeKey={activeKey}>
          <Tabs.TabPane tab="Step 01" key="1" disabled={activeKey !== '1'}>
            <h3>Secure Alert Information</h3>
            <span className="subtitle">
              Information entered here can't be changed later
            </span>
            <Row gutter={20}>
              <Col span={24}>
                <FormItem
                  id="alertTitle"
                  label="Secure Alert Title"
                  hasFeedback
                  options={{
                    rules: [
                      {
                        type: 'string',
                        required: true,
                        message: mandatoryFieldWarning,
                      },
                    ],
                  }}
                >
                  <Input
                    maxLength={120}
                    placeholder="Enter Secure Alert title"
                  />
                </FormItem>
              </Col>
            </Row>
            <div className="form-row">
              <div className="form-row-col">
                <FormItem
                  id="appointedContact"
                  label="Appointed Contact"
                  hasFeedback
                  options={{
                    rules: [
                      {
                        type: 'string',
                        required: true,
                        message: mandatoryFieldWarning,
                      },
                    ],
                  }}
                >
                  <Select
                    allowClear
                    showSearch
                    className="user-select-dropdown"
                    filterOption={false}
                    onSearch={q => setSearchTerm(q)}
                    placeholder="Search and select"
                    showArrow={false}
                  >
                    {users.map(({ userId, name }) => (
                      <Select.Option key={userId} value={userId}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </div>
              <div className="form-row-col">
                <FormItem
                  data-uie-name="option-list-hazard"
                  id="hazardLevel"
                  label="Impact Level"
                  options={{ initialValue: 1 }}
                  className="severity-form-item"
                >
                  <Radio.Group>
                    <div data-uie-name="option-list-severity">
                      <Radio.Button
                        data-uie-name="item-option-severity"
                        data-uie-value="1"
                        value={1}
                      >
                        {' '}
                        1{' '}
                      </Radio.Button>
                      <Radio.Button
                        data-uie-name="item-option-severity"
                        data-uie-value="2"
                        value={2}
                      >
                        {' '}
                        2{' '}
                      </Radio.Button>
                      <Radio.Button
                        data-uie-name="item-option-severity"
                        data-uie-value="3"
                        value={3}
                      >
                        {' '}
                        3{' '}
                      </Radio.Button>
                      <Radio.Button
                        data-uie-name="item-option-severity"
                        data-uie-value="4"
                        value={4}
                      >
                        {' '}
                        4{' '}
                      </Radio.Button>
                    </div>
                  </Radio.Group>
                </FormItem>
              </div>
            </div>
            <Row>
              <FormItem
                id="customMessage"
                label="Message"
                hasFeedback
                options={{
                  rules: [
                    {
                      type: 'string',
                      required: true,
                      message: mandatoryFieldWarning,
                    },
                  ],
                }}
              >
                <Input.TextArea
                  placeholder="Type your message here"
                  maxLength={400}
                />
              </FormItem>
            </Row>
            <Row>
              <DynamicFormItems
                dataUieName="enter-user-response"
                id="userAnswers"
                label="User Answers"
                errorMessage="Please specify"
                inputPlaceholder="Type Here"
                initialValues={
                  (selectedTemplate && selectedTemplate.responses) || []
                }
                formItemsLimit={5}
              />
            </Row>
            {/* <div
              style={{
                marginTop: 60,
                fontWeight: 700,
                fontSize: 11,
                color: '#3a3a3a',
              }}
            >
              AFFECTED LOCATIONS:
            </div>
            <div style={{ paddingBottom: 10, fontSize: 11 }}>
              All personnel in selected locations will be selected for sending.
              You can manage senders in the next step.
            </div>
            <LocationSearch
              selectedLocations={affectedLocations}
              onSelectionChange={setAffectedLocations}
            /> */}
            <FormItem
              id="alertEndingDateAndTime"
              label="Secure Alert Ending Date And Time"
              options={{
                rules: [
                  {
                    type: 'object',
                  },
                ],
              }}
            >
              <DatePicker showTime />
            </FormItem>
            <div className="divider"></div>
            {/* <h3>Attachment</h3>
            <div>You can upload file up to 3MB</div>
            <FormItem id="attachment" options={{ valuePropName: 'file' }}>
              <Upload
                listType="picture"
                beforeUpload={file => {
                  console.log(file.name);
                  const fileReader = new FileReader();

                  fileReader.onload = (e: any) => {
                    const result = e.target.result as string;
                    const base64 = result.split('base64,')[1];

                    setAttachmentPreview(result);

                    uploadAttachmentData({
                      id: 0,
                      filename: file.name,
                      mimeType: file.type,
                      data: base64,
                    });
                  };
                  fileReader.readAsDataURL(file);

                  return false;
                }}
                fileList={fileList}
                onChange={handleAttachmentChange}
              >
                <WhiteButton className="btn btn-white">
                  Upload Image
                  <i className="icon ion-ios-arrow-up" />
                </WhiteButton>
              </Upload>
            </FormItem> */}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Step 02" key="2" disabled={activeKey !== '2'}>
            {/* <div className="selected-locations" style={{ marginBottom: 30 }}>
              <Collapse accordion>
                <Collapse.Panel
                  key="1"
                  header={`Selected Locations (${(affectedLocations && affectedLocations.length) || 0
                    })`}
                >
                  <ul
                    className="summary-list"
                    style={{ maxHeight: 200, overflowY: 'auto' }}
                  >
                    {affectedLocations &&
                      affectedLocations.map(location => (
                        <li key={location.groupId}>
                          <Row type="flex" justify="space-between">
                            <Col>{location.name}</Col>
                          </Row>
                        </li>
                      ))}
                  </ul>
                </Collapse.Panel>
              </Collapse>
            </div> */}
            <div id="select-users-groups-container">
              <Row
                className="search-bar"
                align="middle"
                gutter={8}
                style={{ marginBottom: 25 }}
              >
                <Col className="content">
                  <div>
                    <div className="section-title" style={{ fontSize: 14 }}>
                      GROUPS AND USERS
                    </div>
                    <div className="section-subtitle" style={{ fontSize: 12 }}>
                      You can open every group and select or deselect users if
                      needed
                    </div>
                  </div>
                </Col>
                <Col style={{ textAlign: 'right' }} className="option">
                  <div
                    className="sending-list-button"
                    onClick={() => setSendingMode(true)}
                    style={{ display: sendingMode ? 'none' : 'flex' }}
                  >
                    <div className="sending-list-title">View Sending List</div>
                    <div className="sending-list-tag">{totalSelectedUsers}</div>
                  </div>
                  <WhiteButton
                    className="back-list-button"
                    onClick={() => setSendingMode(false)}
                    style={{
                      display: sendingMode ? 'flex' : 'none',
                    }}
                  >
                    <ChevronBack
                      width="16px"
                      height="16px"
                      color="currentColor"
                    />
                    <span style={{ display: 'inline-flex', marginLeft: 8 }}>
                      Back
                    </span>
                  </WhiteButton>
                </Col>
              </Row>
              <span
                className="sending"
                style={{ display: sendingMode ? 'inline' : 'none' }}
              >
                Sending list
              </span>
              <Input
                className="search-users-groups search-input"
                allowClear
                placeholder="Search here..."
                onChange={e => setUsersGroupsSearchTerm(e.target.value)}
                prefix={<SearchOutlined />}
                style={{
                  display: sendingMode ? 'none' : 'block',
                }}
              />
              <Divider
                style={{ margin: 0, marginTop: 10, marginBottom: -20 }}
              />
              {sendingMode && (
                <div className="ant-form-item select-box">
                  <div className="ant-form-item-control-wrapper">
                    <div className="ant-form-item-control">
                      <span className="ant-form-item-children">
                        {renderSelectedUsersGroups()}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {!sendingMode && (
                <div className="ant-form-item">
                  <div className="ant-form-item-control-wrapper">
                    <div className="ant-form-item-control">
                      <span className="ant-form-item-children">
                        <Checkbox.Group
                          onChange={handleUsersGroupsSelectionChange}
                          className="selected-users-group"
                          defaultValue={selectedUsersGroups.map(
                            getKeyValueForEntry,
                          )}
                        >
                          {usersGroups.map(entry =>
                            'groupId' in entry ? (
                              <ExpandableCheckbox
                                key={getKeyValueForEntry(entry)}
                                fetchItems={() =>
                                  fetchUsersInGroup((entry as Group).groupId)
                                }
                                filterItem={(query, item) =>
                                  item.name.includes(query)
                                }
                                style={{ display: 'block', margin: 0 }}
                                renderItem={item => (
                                  <UserGroup userGroup={item} />
                                )}
                                value={getKeyValueForEntry(entry)}
                                checked={
                                  selectedUsersGroups.findIndex(
                                    x =>
                                      getKeyValueForEntry(x) ===
                                      getKeyValueForEntry(entry),
                                  ) !== -1
                                }
                              >
                                <UserGroup userGroup={entry} />
                              </ExpandableCheckbox>
                            ) : (
                              <Checkbox
                                key={getKeyValueForEntry(entry)}
                                value={getKeyValueForEntry(entry)}
                                checked={
                                  selectedUsersGroups.findIndex(
                                    x =>
                                      getKeyValueForEntry(x) ===
                                      getKeyValueForEntry(entry),
                                  ) !== -1
                                }
                              >
                                <UserGroup userGroup={entry} />
                              </Checkbox>
                            ),
                          )}
                        </Checkbox.Group>
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Step 03" key="3" disabled={activeKey !== '3'}>
            {renderSummary()}
            <Row style={{ marginTop: 20 }}>
              <h4>SAVE TEMPLATE</h4>
              <p>
                Enter name and save this form for later use. All templates can
                be changed afterwards.
              </p>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center' }}>
              <Col span={12}>
                <Input
                  placeholder="Enter Template Name"
                  value={templateTitle}
                  onPressEnter={e => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  onChange={e => setTemplateTitle(e.target.value)}
                />
              </Col>
              <Col span={12} style={{ padding: 4 }}>
                <WhiteButton
                  disabled={templateTitle.length === 0}
                  onClick={handleSaveOrUpdateTemplate}
                  className="btn btn-blue-inverted"
                  style={{
                    marginLeft: 20,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Icon style={{ margin: '0 12px 0 0' }} type="save" />
                  <span>Save</span>
                </WhiteButton>
              </Col>
            </Row>
            {selectedTemplate && (
              <Row>
                <Checkbox
                  checked={overWriteTemplate}
                  onChange={({ target: { checked } }) =>
                    setOverwriteTemplate(checked)
                  }
                >
                  Update existing template
                </Checkbox>
              </Row>
            )}
          </Tabs.TabPane>
        </Tabs>
        <div className="divider big line"></div>
        <div className="form-footer">
          <WhiteButton className="btn btn-grey" onClick={handlePrevious}>
            {activeKey === '1' ? 'Cancel' : 'Go Back'}
          </WhiteButton>
          <WhiteButton
            className="btn btn-blue-inverted"
            onClick={handleNextConfirm}
          >
            {activeKey === '3' ? 'Finish and Send' : 'Next Step'}
            <ArrowForward
              style={{ marginLeft: 10 }}
              width="12px"
              height="12px"
              color="currentColor"
            />
          </WhiteButton>
        </div>
      </Form>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  attachment: getApp(state).attachment,
  page: getApp(state).page,
  pageSize: getApp(state).pageSize,
  searchType: getApp(state).searchType,
  sortOrder: getApp(state).sortOrder,
  users: getApp(state).users,
  usersGroups: getApp(state).usersGroups,
  usersGroupsCached: getApp(state).usersGroupsCached,
});

export default connect(mapStateToProps, {
  fetchUsersForQuery,
  nextPage,
  previousPage,
  saveTemplate,
  setTemplate,
  setRowsPerPage,
  setQuery,
  setSearchType,
  toggleUsersGroupsSort,
  uploadAttachmentData,
  appendToUsersGroupsCached,
})(CreateAlert);
