import React from 'react';

import { Typography, Row, Divider, Col } from 'antd';
import CreateAlertButton from '../CreateAlertButton';
import { useHistory } from 'react-router';
import Templates from '../Templates';
import WhiteButton from '../WhiteButton';
import { DoubleLeftOutlined } from '@ant-design/icons';
import { PaperPlane } from 'react-ionicons';

const { Text } = Typography;

export const CreateAlertLandingPage = () => {
  const history = useHistory();

  return (
    <div>
      <Row>
        <Col span={8}></Col>
        <Col span={8}>
          <Text
            style={{
              fontWeight: 500,
              fontSize: 12,
              width: '100%',
              textAlign: 'center',
              display: 'inline-block',
            }}
          >
            Welcome to eAlarming builder!
          </Text>
        </Col>
        <Col span={8}></Col>
      </Row>
      <Row>
        <Divider />
      </Row>
      <Row style={{ marginBottom: 5 }}>
        <Col span={12}>
          <Text style={{ fontWeight: 700, fontSize: 12 }}>
            CREATE NEW ALARM
          </Text>
        </Col>
      </Row>
      <Row style={{ marginBottom: 30 }}>
        <Col span={12}>
          <Text style={{ fontSize: 12 }} type="secondary">
            Start empty form and create new alarm
          </Text>
        </Col>
      </Row>
      <Row style={{ marginBottom: 30 }}>
        <Col span={12}>
          <CreateAlertButton
            data-uie-name="do-create-alert"
            className="btn btn-blue-inverted"
            onClick={() => {
              history.push('/createAlert');
            }}
          >
            <span>Create New Alert</span>
            <PaperPlane color="currentColor" />
          </CreateAlertButton>
        </Col>
      </Row>
      <Row>
        <Divider />
      </Row>
      <Row>
        <Templates
          title={
            <div>
              <Row>
                <Text style={{ fontSize: 12, fontWeight: 700 }}>
                  TEMPLATE PICKER
                </Text>
              </Row>
              <Row>
                <Text style={{ fontSize: 12 }} type="secondary">
                  Choose template to start from
                </Text>
              </Row>
            </div>
          }
          canDelete={false}
        />
      </Row>
      <Divider />
      <Row>
        <WhiteButton
          className="btn btn-grey"
          onClick={() => history.push('/dashboard')}
        >
          <DoubleLeftOutlined style={{ marginRight: 10 }} />
          <span>Go Back</span>
        </WhiteButton>
      </Row>
    </div>
  );
};
