import { all, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from 'redux-starter-kit';
import queryString from 'query-string';

import { unionBy } from 'lodash';

import api from './services/api';
import {
  fetchConfigSuccess,
  fetchUsersGroups,
  fetchUsersGroupsFail,
  fetchUsersGroupsSuccess,
  getApp,
  searchLocationsFail,
  searchLocationsSuccess,
  setUsersGroupsCached,
} from './reducer';
import { User, Group as WireGroup, Group } from './types';

function sortAlphabetically(a: User | WireGroup, b: User | WireGroup) {
  let first: string;
  let second: string;

  if ('conversationId' in a) {
    first = `${a.name}`;
  } else {
    first = a.name;
  }

  if ('conversationId' in b) {
    second = `${b.name}`;
  } else {
    second = b.name;
  }

  return first.localeCompare(second);
}

export function* search() {
  try {
    yield put(fetchUsersGroups());

    const { query, page, pageSize, sortOrder } = yield select(state =>
      getApp(state),
    );

    const baseQuery = {
      page,
      size: pageSize / 2,
      sort: sortOrder,
      q: query,
    };

    const groupsParams = queryString.stringify({
      ...baseQuery,
      type: 'DEPARTMENT',
    });

    // const usersParams = queryString.stringify({
    //   ...baseQuery,
    //   type: 'EMPLOYEE',
    // });

    const groupsResponse: ResponseGenerator = yield api.get(`/search?${groupsParams}`);
    const usersResponse: ResponseGenerator = yield api.get(`/search?q=`);

    const usersGroups = [
      ...(groupsResponse.data.groups || []),
      ...(usersResponse.data.conversations || []),
    ];

    usersGroups.sort(sortAlphabetically);

    const currentUsersGroups: Array<User | Group> = yield select(state => state.app.usersGroupsCached);

    const usersFromState = currentUsersGroups.filter(ug => 'conversationId' in ug);
    const groupsFromState = currentUsersGroups.filter(ug => 'groupId' in ug);

    const usersFromAction = usersGroups.filter(ug => 'conversationId' in ug);
    const groupsFromAction = usersGroups.filter(ug => 'groupId' in ug);

    const usersUnion = unionBy(usersFromState, usersFromAction, 'conversationId');
    const groupsUnion = unionBy(groupsFromState, groupsFromAction, 'groupId');

    yield put(fetchUsersGroupsSuccess(usersGroups));
    yield put(setUsersGroupsCached([...usersUnion, ...groupsUnion]));
  } catch (e) {
    console.error('users groups fetch fail: ', e);
    yield put(fetchUsersGroupsFail(e.message));
  }
}

export function* searchLocations({ payload }: PayloadAction<string>) {
  try {
    const params = queryString.stringify({
      page: 1,
      size: 20,
      sort: 'ASC',
      q: payload,
      type: 'LOCATION',
    });
    const response: ResponseGenerator = yield api.get(`/search?${params}`);

    yield put(searchLocationsSuccess(response.data.groups || []));
  } catch (e) {
    yield put(searchLocationsFail(e.message));
  }
}

export function* fetchConfig() {
  const { data } = yield api.get('/properties');
  yield put(fetchConfigSuccess(data));
}

function* searchSaga() {
  yield takeLatest('app/nextPage', search);
  yield takeLatest('app/previousPage', search);
  yield takeLatest('app/setPageSize', search);
  yield takeLatest('app/setQuery', search);
  yield takeLatest('app/setSearchType', search);
  yield takeLatest('app/toggleUsersGroupsSort', search);
  yield takeLatest('app/fetchConfig', fetchConfig);
}

function* watchForSearchLocation() {
  yield takeLatest('app/searchLocations', searchLocations);
}

function* append(action: PayloadAction<{ users: User[], groups: Group[] }>) {
  const usersGroupsCached: Array<User | Group> = yield select(state => getApp(state).usersGroupsCached);

  const usersFromAction = action.payload.users;
  const groupsFromAction = action.payload.groups;

  const usersFromState = usersGroupsCached.filter(ug => 'conversationId' in ug);
  const groupsFromState = usersGroupsCached.filter(ug => 'groupId' in ug);

  const usersUnion = unionBy(usersFromState, usersFromAction, 'conversationId');
  const groupsUnion = unionBy(groupsFromState, groupsFromAction, 'groupId');

  yield put(setUsersGroupsCached([...usersUnion, ...groupsUnion]));
}

function* watchForAppendToUsersGroupsCached() {
  yield takeLatest('app/appendToUsersGroupsCached', append);
}

export default function* appSaga() {
  yield all([searchSaga(), watchForSearchLocation(), watchForAppendToUsersGroupsCached()]);
}

export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: number,
  statusText?: string
}