import { createSlice, PayloadAction } from 'redux-starter-kit';
import { Template } from './types';

interface InitialState {
  currentTemplate?: Template;
  error: string;
  isLoading: boolean;
  templates: Template[];
  sendingTemplates: Record<number, boolean>;
  sendingTemplatesErrors: Record<number, string>;
}

const initialState: InitialState = {
  error: '',
  isLoading: false,
  templates: [],
  sendingTemplates: {},
  sendingTemplatesErrors: {},
};

const templatesSlice = createSlice({
  slice: 'templates',
  initialState: initialState,
  reducers: {
    fetchTemplate(state) {
      state.isLoading = true;
    },
    fetchTemplateSuccess(state, action: PayloadAction<Template | undefined>) {
      state.currentTemplate = action.payload;
      state.isLoading = false;
    },
    fetchTemplateFail(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    fetchTemplates(state) {
      state.isLoading = true;
    },
    fetchTemplatesSuccess(state, action: PayloadAction<Template[]>) {
      state.templates = action.payload;
      state.isLoading = false;
    },
    fetchTemplatesFail(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    sendTemplateAlert(state, action: PayloadAction<number>) {
      state.sendingTemplates[action.payload] = true;
    },
    sendTemplateAlertSuccess(state, action: PayloadAction<number>) {
      delete state.sendingTemplates[action.payload];
    },
    sendTemplateAlertError(state, action: PayloadAction<{ id: number, error: string }>) {
      state.sendingTemplatesErrors[action.payload.id] = action.payload.error;
      delete state.sendingTemplates[action.payload.id];
    },
    sendTemplateAlertClearError(state, action: PayloadAction<number>) {
      delete state.sendingTemplatesErrors[action.payload];
    }
  },
});

const { actions, reducer, selectors } = templatesSlice;

export const {
  fetchTemplate,
  fetchTemplateFail,
  fetchTemplateSuccess,
  fetchTemplates,
  fetchTemplatesFail,
  fetchTemplatesSuccess,
  sendTemplateAlert,
  sendTemplateAlertError,
  sendTemplateAlertSuccess,
  sendTemplateAlertClearError,
} = actions;
export const { getTemplates } = selectors;
export default reducer;
