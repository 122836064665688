import { useMemo } from 'react';

type PropertyExtractor<T> = (entity: T) => string;

export function useSearchFilter<T>(
  searchTerm: string,
  items: T[],
  propertyExtractor: PropertyExtractor<T>,
): T[] {
  const filteredValues = useMemo(() => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return items.filter(item =>
      propertyExtractor(item).toLowerCase().includes(lowerCaseSearchTerm)
    );
  }, [items, searchTerm, propertyExtractor]);

  return filteredValues;
}
