import { Button } from 'antd';
import React, { useCallback } from 'react';

import moment from 'moment';

import { getStatusInfo } from '../Reports/components/Report/Report';

import './style.scss';
import { Document } from 'react-ionicons';

interface AlertReportItemProps {
  severity: number;
  title: string;
  status: number;
  starting: string;
  alertResponseId: number;
  responsesCount: number;
  onViewReport: (alertResponseId: number) => void;
}

const AlertReportItem: React.FC<AlertReportItemProps> = ({
  severity,
  title,
  status,
  starting,
  alertResponseId,
  responsesCount,
  onViewReport,
}) => {
  const onViewReportClick = useCallback(() => {
    onViewReport(alertResponseId);
  }, [onViewReport, alertResponseId]);

  return (
    <div className={`alert-item-row severity-0${severity} according-box`}>
      <div className="alert-item-row-title title">{title}</div>
      <div className="alert-item-details-row">
        <div className="alert-item-details-col">
          <div className="alert-item-row-stats">
            <div className="item-box">
              <div
                className="item-stat"
                data-uie-name="info-severity"
                data-uie-value={severity}
              >
                <b className="tag">Impact Level:</b> {severity} / 4
              </div>
              <div className="item-stat">
                <b className="tag">Responses:</b> {responsesCount}
              </div>
            </div>
            <div className="item-box">
              <div
                className="item-stat"
                data-uie-name="info-alert-status"
                data-uie-value={getStatusInfo(status)}
              >
                <b className="tag">Status:</b> {getStatusInfo(status)}
              </div>
              <div
                className="item-stat"
                data-uie-name="info-alert-start-on"
                data-uie-value={moment(starting).format('DD.MM.YYYY. HH:mm:ss')}
              >
                <b className="tag">Sent at:</b>{' '}
                {moment.utc(starting).local().format('DD.MM.YYYY. HH:mm:ss')}
              </div>
            </div>
          </div>
        </div>
        <div className="view-report-col">
          <Button
            data-uie-name="go-view-report"
            className="label"
            size="small"
            onClick={onViewReportClick}
          >
            <Document color="currentColor" />
            View Report
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AlertReportItem;
