import moment from 'moment';
import { createAction } from 'redux-starter-kit';

import api, { createAlert } from '../../services/api';
import { Alert } from '../../types';
import {
  fetchTemplate,
  fetchTemplateFail,
  fetchTemplateSuccess,
  fetchTemplates,
  fetchTemplatesFail,
  fetchTemplatesSuccess,
  sendTemplateAlert,
  sendTemplateAlertSuccess,
  sendTemplateAlertError,
} from './reducer';
import { Template } from './types';

export function fetchTemplateById(templateId?: number) {
  return async (dispatch: any) => {
    dispatch(fetchTemplate());

    if (!templateId) {
      return dispatch(fetchTemplateSuccess(undefined));
    }

    try {
      const response = await api.get(`/templates/${templateId}`);
      return dispatch(fetchTemplateSuccess(response.data));
    } catch (error) {
      return dispatch(fetchTemplateFail(error.message));
    }
  };
}

export function fetchAllTemplates() {
  return async (dispatch: any) => {
    dispatch(fetchTemplates());

    const pageSize = 20;
    let currentPage = 1;
    let templates: Template[] = [];
    let fetchedTemplates: Template[];

    try {
      do {
        const response = await api.get(`/templates?size=${pageSize}&page=${currentPage}`);
        fetchedTemplates = response.data.items;
        templates = [...templates, ...fetchedTemplates];
        currentPage++;
      } while (fetchedTemplates.length === pageSize)

      return dispatch(fetchTemplatesSuccess(templates));
    } catch (error) {
      return dispatch(fetchTemplatesFail(error.message));
    }
  };
}

export const startSetTemplate = createAction('templates/startSetTemplate');
export const setTemplateSuccess = createAction('templates/setTemplateSuccess');
export const setTemplateFail = createAction('templates/setTemplateSuccess');

export function setTemplate(template: Template) {
  return async (dispatch: any) => {
    dispatch(startSetTemplate());

    try {
      await api.put(`/templates/${template.id}`, template);
      return dispatch(setTemplateSuccess());
    } catch (error) {
      return dispatch(setTemplateFail(error.message));
    }
  };
}

export const startSaveTemplate = createAction('templates/startSaveTemplate');
export const saveTemplateSuccess = createAction(
  'templates/saveTemplateSuccess',
);
export const saveTemplateFail = createAction('templates/saveTemplateSuccess');

export function saveTemplate(template: Template) {
  return async (dispatch: any) => {
    dispatch(startSaveTemplate());

    try {
      const response = await api.post(`/templates`, template);
      return dispatch(saveTemplateSuccess(response.data));
    } catch (error) {
      return dispatch(saveTemplateFail(error.message));
    }
  };
}

export const startRemoveTemplate = createAction(
  'templates/startRemoveTemplate',
);
export const removeTemplateSuccess = createAction(
  'templates/removeTemplateSuccess',
);
export const removeTemplateFail = createAction(
  'templates/removeTemplateSuccess',
);

export function removeTemplate(templateId: number) {
  return async (dispatch: any) => {
    dispatch(startRemoveTemplate());

    try {
      await api.delete(`/templates/${templateId}`);
      return dispatch(removeTemplateSuccess());
    } catch (error) {
      return dispatch(removeTemplateFail(error.message));
    }
  };
}

export function sendTemplate(template: Template) {
  return async (dispatch: any) => {
    dispatch(sendTemplateAlert(template.id));

    const alertFromTemplate: Alert = {
      contact: template.contact || '',
      attachment: template.attachmentId,
      groups: template.groups,
      conversations: template.users,
      message: template.message,
      title: template.title,
      responses: template.responses,
      starting: moment(),
      locations: template.locations !== null ? template.locations : [],
      severity: template.severity,
    };

    try {
      await createAlert(alertFromTemplate, true);
      dispatch(sendTemplateAlertSuccess(template.id));
    } catch (error) {
      dispatch(sendTemplateAlertError({ id: template.id, error: error.message }));
    }
  }
}
