import React, { useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import Helmet from 'react-helmet';
import moment from 'moment';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Col, Row, Select, Typography, List } from 'antd';

import { fetchAllReports, fetchReportData } from './actions';
import { getDashboard, selectAlert } from './reducer';
import { getStatusInfo } from '../Reports/components/Report/Report';
import { AlertResponse, Report } from '../../types';

import './style.scss';
import CreateAlertButton from '../CreateAlertButton';
import { AlertReportItem } from '../AlertReportItem';
import { ReportStatisticsBarChart } from '../ReportStatisticsBarChart/ReportStatisticsBarChart';
import { PaperPlane } from 'react-ionicons';

const { Option } = Select;
const { Title } = Typography;

export interface DashboardProps extends RouteComponentProps {
  currentReport?: Report;
  fetchAllReports: () => Promise<any>;
  fetchReportData: (alertId: number) => Promise<any>;
  reports: AlertResponse[];
  selectAlert: (alertId: number) => void;
  selectedAlertId?: number;
}

const renderCharts = (report?: Report) => {
  if (!report) return null;

  const { sent, scheduled } = report;

  return (
    <div className="doughnut-charts">
      <div className="doughnut-chart">
        <Doughnut
          data={{
            datasets: [
              {
                data: [sent, scheduled - sent],
                backgroundColor: ['#fbcf60', '#f5f5f5', 'white'],
              },
            ],
            labels: ['Sent', 'Not Sent'],
          }}
          options={{ legend: { position: 'bottom' } }}
          height={90}
          width={100}
        />
      </div>
      <div className="doughnut-chart" style={{ padding: 10 }}>
        <ReportStatisticsBarChart
          sent={report.sent}
          delivered={report.delivered}
          responded={report.responded}
          read={report.read}
          smsSent={report.smsSent}
          height={250}
        />
      </div>
    </div>
  );
};

const StatEntry: React.FC<{ title: string; value: string }> = ({
  title,
  value,
}) => (
  <div className="statistics-card">
    <div className="statistics-type">{title}</div>
    <div className="statistics-metric">{value}</div>
  </div>
);

const Dashboard = ({
  currentReport,
  fetchAllReports,
  fetchReportData,
  history,
  reports,
  selectAlert,
  selectedAlertId,
}: DashboardProps) => {
  const csvData = [
    ['Message', 'Status', 'Sent On', 'Severity'].join('\t'),
    ...reports.map(x =>
      [x.message, getStatusInfo(x.status), x.starting, x.severity].join('\t'),
    ),
  ].join('\n');

  useEffect(() => {
    fetchAllReports();
  }, [fetchAllReports]);

  useEffect(() => {
    selectedAlertId && fetchReportData(selectedAlertId);
  }, [fetchReportData, selectedAlertId]);

  return (
    <>
      <Helmet title="Secure alerts | Dashboard" />
      <Row className="pageHeader">
        <Col span={12}>
          <Title level={3} style={{ fontWeight: 500 }}>
            DASHBOARD
          </Title>
        </Col>
        <Col span={12}>
          <CreateAlertButton
            data-uie-name="do-create-alert"
            className="btn btn-blue-inverted pull-right"
            onClick={() => {
              history.push('/createAlertLanding');
            }}
          >
            <span>Create New Alert</span>
            <PaperPlane color="currentColor" />
          </CreateAlertButton>
        </Col>
      </Row>
      <div className="statistics">
        <Row>
          <Col className="ant-col-xs-24 ant-col-md-18">
            <Select
              className="select-alert-report"
              dropdownMatchSelectWidth={false}
              value={selectedAlertId}
              onSelect={selectAlert}
            >
              {reports.map(({ id, starting, title }) => (
                <Option key={id} value={id}>
                  <div>
                    <span className="select-alert-report__title">{title} </span>
                    <span className="select-alert-report__subtitle">
                      as of{' '}
                      {moment
                        .utc(starting)
                        .local()
                        .format('DD.MM.YYYY. HH:mm:ss')}
                    </span>
                  </div>
                </Option>
              ))}
            </Select>
            {renderCharts(currentReport)}
          </Col>
          <Col className="vertical-divider ant-col-xs-24 ant-col-md-6">
            {currentReport && (
              <>
                <StatEntry
                  title="Scheduled"
                  value={`${currentReport.scheduled}`}
                />
                <StatEntry title="Sent" value={`${currentReport.sent}`} />
                <StatEntry
                  title="Delivered"
                  value={`${currentReport.delivered}`}
                />
                <StatEntry
                  title="Read / Responded"
                  value={`${currentReport.read} / ${currentReport.responded}`}
                />
                {/* <StatEntry
                  title="Sent via SMS"
                  value={`${currentReport.smsSent}`}
                /> */}
              </>
            )}
          </Col>
        </Row>
      </div>
      <h2>Recent Secure alerting</h2>
      <List
        header={
          <a
            data-uie-name="do-export-reports"
            href={encodeURI(`data:text/csv;charset=utf-8, ${csvData}`)}
            download="export.csv"
          >
            Export to CSV
          </a>
        }
        bordered={false}
        dataSource={reports}
        renderItem={report => (
          <AlertReportItem
            alertResponseId={report.id}
            onViewReport={id => history.push(`/report/${id}`)}
            title={report.title}
            severity={report.severity}
            responsesCount={report.responses.length}
            starting={report.starting}
            status={report.status}
          />
        )}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentReport: getDashboard(state).currentReport,
  reports: getDashboard(state).reports,
  selectedAlertId: getDashboard(state).selectedAlertId,
});

export default connect(mapStateToProps, {
  fetchAllReports,
  fetchReportData,
  selectAlert,
})(Dashboard);
