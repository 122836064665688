import { useEffect, useRef, useState } from 'react';
import { fetchUsersInGroup } from '../services/api';
import { User, Group } from '../types';

export const useUsersGroupsCount = (
  selectedUsersGroups: Array<User | Group>,
) => {
  const [totalUsersGroupsCount, setTotalUsersGroupsCount] = useState(0);
  const usersInGroupLookup = useRef<Record<string, number>>({});

  useEffect(() => {
    const updateUsersCount = async () => {
      console.log("selectedUsersGroups:", selectedUsersGroups);
      const selectedUsers = selectedUsersGroups.filter(ug => 'conversationId' in ug);

      let totalCount = selectedUsers.length;

      const selectedGroups = selectedUsersGroups.filter(
        ug => 'groupId' in ug,
      ) as Group[];

      for (let i = 0; i < selectedGroups.length; i++) {
        const group = selectedGroups[i];

        if (usersInGroupLookup.current[group.groupId] === undefined) {
          const usersInGroup = await fetchUsersInGroup(group.groupId);
          const usersInGroupCount = usersInGroup.length;

          // Memoize user count value for future reference
          usersInGroupLookup.current[group.groupId] = usersInGroupCount;

          // Add total number of users in group to total count
          totalCount += usersInGroupCount;
        } else {
          totalCount += usersInGroupLookup.current[group.groupId];
        }
      }

      setTotalUsersGroupsCount(totalCount);
    };

    updateUsersCount();
  }, [selectedUsersGroups]);

  return totalUsersGroupsCount;
};
