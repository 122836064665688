import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import CreateAlert from '../CreateAlert';
import Dashboard from '../Dashboard';
import Report from '../Reports/components/Report';
import Reports from '../Reports';
import Templates from '../Templates';
import { CreateAlertLandingPage } from '../CreateAlertLandingPage/CreateAlertLandingPage';

export const AppContent = () => (
  <Switch>
    <Route path="/dashboard" component={Dashboard} />
    <Route path="/reports" component={Reports} />
    <Route path="/report/:reportId" component={Report} />
    <Route path="/templates" component={Templates} />
    <Route path="/createAlertLanding" component={CreateAlertLandingPage} />
    <Route path="/createAlert" component={CreateAlert} />
    <Redirect from="/" to="/dashboard" exact />
    <Route component={() => <div>Not Found</div>} />
  </Switch>
);
