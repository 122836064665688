import React, { HTMLProps, useEffect, useRef } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Col, Layout, Menu, Row, Tooltip } from 'antd';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { ApplicationState, fetchConfig } from '../../reducer';

import { AppContent } from '../Content/Content';
import { baseURL } from '../../services/api';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Apps, Megaphone, Layers } from 'react-ionicons';

const { REACT_APP_CLIENT_VERSION } = process.env;

const { Content, Header, Sider } = Layout;

export interface MenuItemProps extends HTMLProps<HTMLDivElement> {
  Icon: React.FC<React.HTMLProps<HTMLElement>>;
  link?: string;
  text: string;
}

const MenuItemContent = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  width: 100%;
  span {
    display: inline-flex;
    svg {
      color: currentColor;
    }
  }
`;

const MenuItem = ({ text, Icon, link, ...divProps }: MenuItemProps) => (
  <MenuItemContent {...divProps}>
    <Icon color="currentColor" style={{ paddingRight: 12 }} />
    <Link
      to={link || '/'}
      style={{ color: 'currentColor', display: 'inline-block', width: '100%' }}
    >
      {text}
    </Link>
  </MenuItemContent>
);

const Brand = styled.div`
  color: #363636;
  cursor: pointer;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
`;

const Copyright = styled.div`
  color: #020202;
  font-size: 12px;
  font-weight: 300;
  line-height: 11px;
`;

const getDisplayForLocation = (location: RouteComponentProps['location']) => {
  switch (location.pathname.split('/')[1]) {
    case 'createAlert':
    case 'template':
      return 'none';
    default:
      return 'inherit';
  }
};

const getClassNameForLocation = (location: RouteComponentProps['location']) => {
  return location.pathname.split('/')[1];
};

const routesWithNoMenu = ['/createAlertLanding', '/createAlert'];

type AppProps = {
  logoUrl: string;
  appTitle: string;
  fetchConfig: Function;
};

export default connect(
  (state: ApplicationState) => ({
    appTitle: state.app.appTitle,
    logoUrl: state.app.logoUrl,
  }),
  { fetchConfig },
)(
  ({
    history,
    location,
    logoUrl,
    appTitle,
    fetchConfig,
  }: RouteComponentProps & AppProps) => {
    const isInitialized = useRef(false);

    useEffect(() => {
      if (!isInitialized.current) {
        fetchConfig();
        isInitialized.current = true;
      }
    }, [fetchConfig]);

    return (
      <Layout>
        <Header className="head">
          <Row className="container">
            <Col span={23}>
              <Brand
                data-uie-name="go-homepage"
                onClick={() => history.push('/')}
              >
                {appTitle}
              </Brand>
              <Copyright>by Wire</Copyright>
            </Col>
            <Col span={1}>
              <Tooltip title={`Version: ${REACT_APP_CLIENT_VERSION}`}>
                <InfoCircleOutlined style={{ position: 'absolute', top: 14 }} />
              </Tooltip>
            </Col>
          </Row>
        </Header>
        <Row className="container mobile-menu">
          <Col span={24}>
            <Menu selectedKeys={[location.pathname]} mode="horizontal">
              <Menu.Item key="/dashboard">
                <MenuItem
                  data-uie-name="go-dashboard"
                  text="Dashboard"
                  Icon={(props: any) => <Apps {...props} />}
                  link="/dashboard"
                />
              </Menu.Item>
              <Menu.Item key="/reports">
                <MenuItem
                  data-uie-name="go-reports"
                  text="Reports"
                  Icon={(props: any) => <Megaphone {...props} />}
                  link="/reports"
                />
              </Menu.Item>
              <Menu.Item key="/templates">
                <MenuItem
                  data-uie-name="go-templates"
                  text="Templates"
                  Icon={(props: any) => <Layers {...props} />}
                  link="/templates"
                />
              </Menu.Item>
            </Menu>
          </Col>
        </Row>
        <Layout className="container wrapper">
          {!routesWithNoMenu.includes(location.pathname) && (
            <Sider
              className="side-menu"
              theme="light"
              style={{
                display: getDisplayForLocation(location),
              }}
            >
              <div>
                <img
                  className="partner-logo"
                  src={`${baseURL}/${logoUrl}`}
                  alt="partner-logo"
                />
              </div>
              <div className="divider small line"></div>
              <Menu selectedKeys={[location.pathname]}>
                <Menu.Item key="/dashboard">
                  <MenuItem
                    data-uie-name="go-dashboard"
                    text="Dashboard"
                    Icon={(props: any) => <Apps {...props} />}
                    link="/dashboard"
                  />
                </Menu.Item>
                <Menu.Item key="/reports">
                  <MenuItem
                    data-uie-name="go-reports"
                    text="Reports"
                    Icon={(props: any) => <Megaphone {...props} />}
                    link="/reports"
                  />
                </Menu.Item>
                <Menu.Item key="/templates">
                  <MenuItem
                    data-uie-name="go-templates"
                    text="Templates"
                    Icon={(props: any) => <Layers {...props} />}
                    link="/templates"
                  />
                </Menu.Item>
              </Menu>
            </Sider>
          )}
          <Content className={getClassNameForLocation(location)}>
            <AppContent />
          </Content>
        </Layout>
      </Layout>
    );
  },
);
