import React, { useState, FormEvent } from 'react';
import { Alert, Button, Input } from 'antd';
import { doInit, signIn } from './services/api';
import App from './App';
import './style.scss';
import { useEffect } from 'react';

const { Password } = Input;

const Login = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string | undefined>();
  const [password, setPassword] = useState<string>('');

  useEffect(() => {
    doInit()
      .then((success) => {
        setLoggedIn(success);
      })
      .catch(error => {
      });
  }, []);

  const handleLogin = (e: FormEvent) => {
    e.preventDefault();
    signIn(email, password)
      .then((newAccessToken) => {
        setLoggedIn(true);
      })
      .catch(error => {
        setError(error.message);
      });
  };

  return loggedIn ? (
    <App />
  ) : (
    <div className="login-panel">
      {error ? (
        <Alert type="error" message="Error" closable description={error} />
      ) : null}
      <Input
        onChange={e => setEmail(e.target.value)}
        placeholder="Email address"
      />
      <Password
        onChange={e => setPassword(e.target.value)}
        placeholder="Password"
      />
      <Button onClick={handleLogin}>Log in</Button>
    </div>
  );
};

export default Login;