import { useEffect } from 'react';

const useInterval = (
  callback: Function,
  delay: number,
  dependencies: readonly any[] | undefined,
) => {
  useEffect(() => {
    const handler = setInterval(() => {
      callback();
    }, delay);
    return () => {
      clearInterval(handler);
    };
  }, [callback, delay, dependencies]);
};

export default useInterval;