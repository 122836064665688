import React from 'react';
import { Bar } from 'react-chartjs-2';

import './style.scss';

interface Props {
  sent: number;
  delivered: number;
  read: number;
  responded: number;
  smsSent: number;
  height?: number;
}

export const ReportStatisticsBarChart: React.FC<Props> = ({
  sent,
  delivered,
  read,
  responded,
  smsSent,
  height,
}) => {
  const sentDeliveredDiff = sent - delivered;

  const chartsData = {
    labels: ['Delivered', 'Read', 'Responded'],
    datasets: [
      {
        label: 'Main',
        data: [delivered, read, responded],
        backgroundColor: ['#E0E0E0', '#68c690', '#6da6f2'],
      },
      {
        label: 'Total',
        data: [sentDeliveredDiff, null, null, null],
        backgroundColor: '#F5F5F5',
      },
    ],
  };

  const options: Chart.ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: true,
            callback: function (_, index, __) {
              if (index === 0 && sent > 0) {
                return sent;
              }

              return '';
            },
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        title: () => '',
        label: (item, data) => {
          console.log(item, data);
          if (item.label === 'Delivered' && item.datasetIndex === 1) {
            return `Total sent: ${sent}`;
          }

          return `${item.label}: ${item.value}`;
        },
      },
    },
  };

  return <Bar data={chartsData} height={height} options={options} />;
};
