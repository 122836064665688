import axios from 'axios';
import { Alert, User, Group } from '../types';
import { APIClient } from '@wireapp/api-client';
import { Config } from '@wireapp/api-client/src/Config';
import { LoginData } from '@wireapp/api-client/src/auth/';
import { ClientType } from '@wireapp/api-client/src/client';
import { isAtLeastAdmin } from '@wireapp/api-client/src/team/member';

const { REACT_APP_API_HOST } = process.env;
const windowRef: any = window;
const backendDomain = windowRef.wire && windowRef.wire.env.BACKEND;
export const baseURL = backendDomain || REACT_APP_API_HOST;

const defaultHeaders = {
  Accept: 'application/json'
};

const api = axios.create({
  baseURL,
  responseType: 'json',
  headers: defaultHeaders,
  withCredentials: true,
});

const apiConfig: Config = {
  urls: APIClient.BACKEND.STAGING
};

const client = new APIClient(apiConfig);

export const doInit = async () => {
  await client.init();
  var selfUser = await client.api.self.getSelf();
  if (!selfUser.team) {
    throw new Error('Authentication failed because of invalid credentials.');
  }
  var selfMember = await client.api.teams.member.getMember(selfUser.team!, selfUser.id);
  if (!isAtLeastAdmin(selfMember.permissions)) {
    throw new Error('Authentication failed because of invalid permissions.');
  }
  return true;
}

export const signIn = async (email: string, password: string) => {
  const credentials: LoginData = {
    clientType: ClientType.PERMANENT,
    email: email.indexOf('@') > 0 ? email : undefined,
    handle: email.indexOf('@') <= 0 ? email.replace('@', '') : undefined,
    password: password
  };
  let accessToken;
  client.on(APIClient.TOPIC.ACCESS_TOKEN_REFRESH, newAccessToken => {
    accessToken = newAccessToken;
  });
  await client.login(credentials);
  var selfUser = await client.api.self.getSelf();
  if (!selfUser.team) {
    throw new Error('Authentication failed because of invalid permissions.');
  }
  var selfMember = await client.api.teams.member.getMember(selfUser.team!, selfUser.id);
  if (!isAtLeastAdmin(selfMember.permissions)) {
    throw new Error('Authentication failed because of invalid permissions.');
  }
  return accessToken;
}

export const createAlert = async (alert: Alert, broadcast?: boolean) => {
  const response = await api.post('/alerts', alert);
  return await (broadcast
    ? api
      .post(`/broadcast/${response.data.alert.id}`, {})
      .then(() => Promise.resolve(response))
    : Promise.resolve(response));
};

export const fetchGroups = async (groupIds: string[]): Promise<Group[]> => {
  // const {
  //   data: { items },
  // } = await api.get(`/groups?id=${groupIds.join(',')}`);

  const items: Group[] = [{} as Group];

  return items;
};

export const fetchUsersInGroup = async (groupId: string): Promise<User[]> => {
  try {
    const response = await api.get(`/groups/${groupId}`);
    return response.data.items;
  } catch (error) {
    console.log(error.message);
  }
  return [];
};

export const fetchUsers = async (userIds: string[]): Promise<User[]> => {
  console.log("fetchUsers start:", userIds);

  const idsQuery = userIds.map(userId => `id=${userId}`).join('&');
  var {
    data: { items },
  } = await api.get(`/users?${idsQuery}`);

  return items;
};

export default api;
