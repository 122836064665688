import React, { useEffect, useCallback, useState } from 'react';
import { Checkbox, Icon, Input, Modal, Spin } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';

import { useDebounce } from '../../../../hooks';

import './style.scss';
import WhiteButton from '../../../WhiteButton';

export interface ExpandableCheckboxProps<T> extends CheckboxProps {
  fetchEnabled?: boolean;
  fetchItems(): Promise<T[]>;
  filterItem(query: string, item: T): boolean;
  onOk?(): void;
  renderItem(item: T): JSX.Element;
}

const ExpandableCheckbox = <T extends {}>({
  fetchEnabled,
  fetchItems,
  filterItem,
  onOk,
  renderItem,
  children,
  ...checkboxProps
}: ExpandableCheckboxProps<T>) => {
  const [visible, setVisible] = useState(false);
  const [items, setItems] = useState<T[]>();
  const [filteredItems, setFilteredItems] = useState<T[]>();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(
    async e => {
      e.preventDefault();

      if (!items) {
        setLoading(true);
        const items = await fetchItems();
        setItems(items);
        setFilteredItems(items);
        setLoading(false);
      }
      setVisible(!visible);
    },
    [fetchItems, items, visible],
  );

  useEffect(() => {
    if (!items) return;

    setFilteredItems(
      items.filter(item => filterItem(debouncedSearchTerm, item)),
    );
  }, [debouncedSearchTerm, filterItem, items]);

  const handleOk = useCallback(() => {
    setVisible(false);

    onOk && onOk();
  }, [onOk]);

  return (
    <Checkbox {...checkboxProps}>
      <span onClick={e => fetchEnabled && handleClick(e)}>
        {children}
        {loading ? (
          <Spin size="small" style={{ float: 'right', marginRight: 24 }} />
        ) : null}
      </span>
      <Modal
        visible={visible}
        width="50%"
        onCancel={() => setVisible(false)}
        footer={<WhiteButton onClick={handleOk}>OK</WhiteButton>}
      >
        {items && filteredItems ? (
          <div>
            <Input
              data-uie-name="input-search-groups"
              allowClear
              className="expandable-checkbox-search"
              placeholder="Search here..."
              prefix={<Icon type="search" />}
              onChange={e => setSearchTerm(e.target.value)}
            />
            <div
              data-uie-name="list-groups"
              className="expandable-checkbox-group"
            >
              {items.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: filteredItems.includes(item) ? 'block' : 'none',
                    position: 'relative',
                  }}
                >
                  {renderItem(item)}
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </Modal>
    </Checkbox>
  );
};

ExpandableCheckbox.defaultProps = {
  fetchEnabled: true,
};

export default ExpandableCheckbox;
