import React, { useState } from 'react';
import { Row, Col, Button, Spin } from 'antd';

import { Template } from '../../types';
import {
  Trash,
  Document,
  PaperPlane,
  ChevronUp,
  ChevronDown,
} from 'react-ionicons';

export interface TemplateProps extends Template {
  canDelete?: boolean;
  onEdit: (template: Template) => void;
  onSendNow: (template: Template) => void;
  onRemove: (templateId: number) => void;
  isSending?: boolean;
}

export const getColorForHazardScale = (hazardScale: number) => {
  if (hazardScale === 1) return '#E0E0E0';
  if (hazardScale === 2) return '#FBCF60';
  if (hazardScale === 3) return '#F7A365';
  if (hazardScale === 4) return '#F57878';

  return 'black';
};

const Template_ = ({
  canDelete,
  onEdit,
  onRemove,
  onSendNow,
  isSending = false,
  ...template
}: TemplateProps) => {
  const { id, message, responses, severity, title } = template;
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      data-uie-name="list-templates"
      data-uie-value={title}
      className="according-box"
    >
      <div className="template-list-content">
        <div className="template-list-col">
          <div className={`template-list-severity severity-0${severity}`}>
            <span>{severity}</span>
          </div>
          <div className="template-list-title">
            <h3 data-uie-name="item-title" className="title">
              {title}
            </h3>
            <span>IMPACT LEVEL: {severity} / 4</span>
          </div>
        </div>
        <div className="template-list-col">
          <div className="template-list-options">
            <div>
              {canDelete && (
                <Button
                  data-uie-name="do-template-remove"
                  className="label"
                  onClick={() => onRemove(id)}
                  size="small"
                >
                  <Trash color="currentColor" />
                  <span>Delete</span>
                </Button>
              )}
            </div>
            <div>
              <Button
                data-uie-name="do-template-edit"
                className="label"
                onClick={() => onEdit(template)}
                size="small"
              >
                <Document color="currentColor" />
                <span>Edit and Send</span>
              </Button>
            </div>
            <div>
              {!isSending ? (
                <Button
                  data-uie-name="do-template-send"
                  className="label"
                  size="small"
                  onClick={() => onSendNow(template)}
                >
                  <PaperPlane color="currentColor" />
                  Send Now
                </Button>
              ) : (
                <div
                  style={{
                    width: 66,
                    height: 52,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '0 12px',
                  }}
                >
                  <Spin tip="Sending..." size="small" />
                </div>
              )}
            </div>
          </div>
          <div className="template-list-expand">
            <span className="label" onClick={() => setExpanded(!expanded)}>
              {expanded ? (
                <ChevronUp color="currentColor" />
              ) : (
                <ChevronDown color="currentColor" />
              )}
            </span>
          </div>
        </div>
      </div>
      {expanded ? (
        <Row>
          <div className="divider small line"></div>
          <Col span={12}>
            <span>
              Responses: <strong>{responses.length}</strong>
            </span>
            <ul data-uie-name="list-templates-response" className="response">
              {responses.map(response => (
                <li
                  data-uie-name="list-item-template-response"
                  data-uie-value={response}
                  key={response}
                >
                  {response}
                </li>
              ))}
            </ul>
          </Col>
          <Col span={12}>
            <span>Message:</span>
            <p data-uie-name="item-template-message" data-uie-value={message}>
              {message}
            </p>
          </Col>
          <div className="divider small"></div>
        </Row>
      ) : null}
    </div>
  );
};

export default Template_;