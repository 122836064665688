import React, { ReactElement, useContext } from 'react';
import { Form } from 'antd';
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form';
import { FormItemProps as AntFormItemProps } from 'antd/lib/form/FormItem';

import { FormContext } from '../Form';

export interface FormItemProps extends AntFormItemProps {
  children: React.ReactNode | React.ReactNode[];
  id: string;
  /**
   * Mostly used to denote field validators, labels and/or initial values.
   * See the docs [here](https://ant.design/components/form/#getFieldDecorator(id,-options)-parameters).
   */
  options?: GetFieldDecoratorOptions;
}

const FormItem = (props: FormItemProps): ReactElement => {
  const { children, id, options, ...formItemProps } = props;
  const form = useContext(FormContext);

  return (
    <Form.Item {...formItemProps}>
      {children instanceof Array ? (
        <>
          {form.getFieldDecorator(id, options)(children[0])}
          {children.filter((x, i) => (i > 0 ? x : null))}
        </>
      ) : (
        form.getFieldDecorator(id, options)(children)
      )}
    </Form.Item>
  );
};

export default FormItem;
