import React from 'react';
import { User, Group } from '../../../../types';
import { InfoIcon } from '../../../InfoIcon';
import { People, Person } from 'react-ionicons';

import './style.scss';

interface UserGroupProps {
  userGroup: User | Group;
  groupData?: string;
}

const getIconClassName = (userGroup: User | Group) => {
  if ('conversationId' in userGroup) {
    if ((userGroup as User).group) {
      return <People width="16px" height="16px" color="currentColor" />;
    }
  }
  return <Person width="16px" height="16px" color="currentColor" />;
};

const UserDetail: React.FC<{ user: User }> = ({ user }) => (
  <span>
    {user.name}
    {<InfoIcon type="wire" />}
    {user.phone && <InfoIcon type="sms" />}
  </span>
);

const GroupDetail: React.FC<{ group: Group; groupData?: string }> = ({
  group,
  groupData,
}) => (
  <span>
    {group.name} {groupData}
  </span>
);

const UserGroup = ({ groupData, userGroup }: UserGroupProps) => (
  <div data-uie-name="list-send-to" className="user-group">
    <span data-uie-name="item-send-to">
      {(('conversationId' in userGroup && (userGroup as User).group) || ('groupId' in userGroup)) ? (
        <GroupDetail group={userGroup as Group} groupData={groupData} />
      ) : (
        <UserDetail user={userGroup as User} />
      )}
    </span>
    {getIconClassName(userGroup)}
  </div>
);

export default UserGroup;