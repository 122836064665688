import styled from '@emotion/styled';

const baseButtonStyle = {};

export default styled.button({
  ...baseButtonStyle,
  '&': {
    textTransform: 'uppercase',
  },
  ':hover': {
    cursor: 'pointer',
  },
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  span: {
    display: 'inline-flex',
    paddingLeft: 16,
    svg: {
      color: 'currentColor',
    },
  },
});
