import React, { useEffect, useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import moment from 'moment';
import { ClassNames } from '@emotion/core';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import {
  Col,
  Divider,
  Modal,
  Row,
  Typography,
  Progress,
  Tooltip,
  Tabs,
  List,
  Dropdown,
  Checkbox,
  Button,
  Spin,
} from 'antd';

import {
  escalateForUser,
  fetchReportData,
  updateReportData,
  updateUserStatusesData,
} from './actions';
import { getReport } from './reducer';
import { useInterval } from '../../../../hooks';
import WhiteButton from '../../../WhiteButton';

import { AlertStatistics } from './AlertStatistics';

import {
  AlertResponse,
  Report as ReportType,
  User,
  UserStatusHistory,
  UserStatus,
} from '../../../../types';

import './style.scss';
import { ReportUserStatusInfo } from './ReportUserStatusInfo';
import { FilterOutlined } from '@ant-design/icons';
import { CheckboxOptionType } from 'antd/lib/checkbox';
import { CSVLink } from 'react-csv';

const { confirm } = Modal;
const { Text } = Typography;
const { Title } = Typography;

interface ReportProps extends RouteComponentProps<{ reportId: string }> {
  alert: Partial<AlertResponse>;
  report: Partial<ReportType>;
  userStatusesHistory: UserStatusHistory[];
  appointedContact: Partial<User>;
  isLoadingReport: boolean;

  updateReportData: (alertId: number) => Promise<any>;
  updateUserStatusesData: (alertId: number) => Promise<any>;
  escalateForUser: (alertId: number, userId: string) => Promise<any>;
  fetchReportData: (alertId: number) => Promise<any>;
}

const filterOptions: CheckboxOptionType[] = [
  {
    label: 'Delivered',
    value: 'DELIVERED',
  },
  { label: 'Escalated', value: 'ESCALATED' },
  { label: 'Read', value: 'READ' },
  { label: 'Responded', value: 'RESPONDED' },
  { label: 'Scheduled', value: 'SCHEDULED' },
  { label: 'Sent', value: 'SENT' },
];

interface UserStatusInfoHeaderProps {
  onApply: (options: string[]) => void;
}

const UserStatusInfoHeader: React.FC<UserStatusInfoHeaderProps> = ({
  onApply,
}) => {
  const [filterVisible, setFilterVisible] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const onApplyClick = () => {
    setFilterVisible(false);
    onApply(selectedOptions);
  };

  const onResetClick = () => {
    setFilterVisible(false);
    setSelectedOptions([]);
    onApply([]);
  };

  const menu = (
    <div className="message-status-filter">
      <Checkbox.Group
        value={selectedOptions}
        options={filterOptions}
        style={{ display: 'flex', flexDirection: 'column' }}
        onChange={values => setSelectedOptions(values as string[])}
      />
      <div style={{ marginTop: 8, borderTop: '1px solid lightgrey' }}>
        <Button type="link" onClick={onApplyClick}>
          Apply
        </Button>
        <Button type="link" onClick={onResetClick}>
          Reset
        </Button>
      </div>
    </div>
  );

  return (
    <div className="user-status-info-header" style={{ width: '100%' }}>
      <div className="user-status-info-name">Full Name / Department</div>
      <div className="user-status-info-message-status">
        <span>Message Status</span>
        <Dropdown visible={filterVisible} overlay={menu}>
          <FilterOutlined
            style={{ marginLeft: 6 }}
            onClick={() => setFilterVisible(true)}
          />
        </Dropdown>
      </div>
      <div className="user-status-info-action" />
    </div>
  );
};

export const getStatusInfo = (status?: number): string => {
  if (status === 0) return 'Scheduled';
  if (status === 1) return 'Message Sent';
  if (status === 2) return 'Finished';

  return '';
};

const Report = ({
  alert,
  escalateForUser,
  fetchReportData,
  history,
  match,
  report,
  updateReportData,
  updateUserStatusesData,
  isLoadingReport,
  userStatusesHistory,
  appointedContact,
}: ReportProps) => {
  const [statusFilterOptions, setStatusFilterOptions] = useState<string[]>([]);

  const userStatuses: UserStatus[] = useMemo(
    () =>
      userStatusesHistory.map(statusHistory => {
        const firstStatus = statusHistory.statusesHistory[0];
        return {
          user: statusHistory.user,
          ...firstStatus,
        };
      }),
    [userStatusesHistory],
  );

  useEffect(() => {
    const alertId = Number(match.params.reportId);
    fetchReportData(alertId);
  }, [fetchReportData, match.params.reportId]);

  useInterval(
    () => {
      const alertId = Number(match.params.reportId);
      updateReportData(alertId);
      updateUserStatusesData(alertId);
    },
    30000,
    [match.params.reportId, updateReportData, updateUserStatusesData],
  );

  const filteredUserStatuses = useMemo(() => {
    if (statusFilterOptions.length === 0) {
      return userStatuses;
    }

    return userStatuses.filter(userStatus =>
      statusFilterOptions.some(o => o === userStatus.status),
    );
  }, [userStatuses, statusFilterOptions]);

  const csvData = useMemo(() => {
    const data = [
      [
        'Full name',
        'Department',
        'Title',
        'Location',
        'Message status',
        'Wire connected',
        'Phone number on AD',
        'SMS escalation',
        'User answer',
        'Sent date and time',
        'SMS sent date and time',
        'Delivered date and time',
        'Read date and time',
        'Responded date and time',
      ],
    ];

    userStatusesHistory.forEach(statusHistory => {
      const user = statusHistory.user;
      const statusesHistory = statusHistory.statusesHistory;

      const rowData = [];

      const isEscalated = statusesHistory.some(s => s.escalated);
      const respondedStatus = statusesHistory.find(
        s => s.status === 'RESPONDED',
      );
      const sentStatus = statusesHistory.find(s => s.status === 'SENT');
      const deliveredStatus = statusesHistory.find(
        s => s.status === 'DELIVERED',
      );
      const readStatus = statusesHistory.find(s => s.status === 'READ');
      const smsSentStatus = statusesHistory.find(s => s.status === 'SMS_SENT');

      rowData.push(
        `${user.name}`,
        '',
        '',
        '',
        statusesHistory[0].status,
        'Yes',
        user.phone ? 'Yes' : 'No',
        isEscalated ? 'Yes' : 'No',
        respondedStatus ? respondedStatus.response : '-',
        sentStatus ? sentStatus.created : '-',
        smsSentStatus ? smsSentStatus.created : '-',
        deliveredStatus ? deliveredStatus.created : '-',
        readStatus ? readStatus.created : '-',
        respondedStatus ? respondedStatus.created : '-',
      );

      data.push(rowData);
    });

    return data;
  }, [userStatusesHistory]);

  const { severity, starting, status, groups, title, message } = alert;

  return isLoadingReport ? (
    <div className="loading-report-container">
      <Spin size="large" tip="Loading report" />
    </div>
  ) : (
    <div className="report-container">
      <Helmet title="Secure alerts | Alert Page" />
      <Row className="pageHeader">
        <Col span={12}>
          <Title level={3} style={{ fontWeight: 500 }}>
            Alert Report
          </Title>
        </Col>
      </Row>
      <div style={{ marginBottom: 40, display: 'flex', alignItems: 'center' }}>
        <div style={{ marginRight: 30 }}>
          <div style={{ fontSize: 20, fontStyle: 'italic', fontWeight: 500 }}>
            {title}
          </div>
          <Text type="secondary" style={{ fontSize: 12 }}>
            sent on{' '}
            {moment.utc(starting).local().format('DD.MM.YYYY. HH:mm:ss')}
          </Text>
        </div>
        <div>
          <ClassNames>
            {({ cx }) => (
              <Tooltip title={`Impact level: ${severity}/4`}>
                <Progress
                  className={cx('hazard', {
                    [`hazard-level-${severity}`]: !!severity,
                  })}
                  type="circle"
                  percent={100}
                  format={() => severity}
                />
              </Tooltip>
            )}
          </ClassNames>
        </div>
        <div style={{ fontWeight: 500, color: 'darkgrey', marginLeft: 30 }}>
          {getStatusInfo(status)}
        </div>
      </div>
      <Row style={{ marginBottom: 40 }}>
        <Col span={14}>
          <Tabs animated={false} defaultActiveKey="1">
            {/* <Tabs.TabPane
              tab={<div className="section-title">LOCATIONS</div>}
              key="1"
            >
              <List
                style={{ maxHeight: 200, overflowY: 'auto', marginRight: 20 }}
                dataSource={alert.groups}
                renderItem={item => (
                  <List.Item style={{ borderBottom: 'none' }}>
                    {item.name}
                  </List.Item>
                )}
              />
            </Tabs.TabPane> */}
            <Tabs.TabPane
              tab={<div className="section-title">SENDING LIST</div>}
              key="2"
            >
              <List
                style={{ maxHeight: 200, overflowY: 'auto', marginRight: 20 }}
                dataSource={userStatuses}
                renderItem={item => (
                  <List.Item>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div className="text-medium text-bold">
                        {item.user.name}
                      </div>
                      <div className="text-small text-secondary">
                        {''}
                      </div>
                      <div className="text-small text-secondary">
                        {''}
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<div className="section-title">APPOINTED CONTACT</div>}
              key="3"
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="text-medium text-bold">
                  {appointedContact && appointedContact.name}
                </div>
                <div className="text-small text-secondary">
                  {''}
                </div>
                <div className="text-small text-secondary">
                  {''}
                </div>
              </div>
            </Tabs.TabPane>
          </Tabs>
          <div>
            {groups &&
              groups
                .filter(group => group.type === 2)
                .map(group => (
                  <div style={{ borderBottom: '1px solid lightgrey' }}>
                    {group.name}
                  </div>
                ))}
          </div>
        </Col>
        <Col span={6}>
          <div className="section-title" style={{ marginBottom: 30 }}>
            SENT MESSAGE
          </div>
          <div style={{ fontSize: 12 }}>{message}</div>
        </Col>
      </Row>
      <AlertStatistics
        report={report}
        alert={alert}
        userStatuses={userStatuses}
      />
      <Row className="alert-section-box" gutter={10}>
        <Col span={24}>
          <div className="group-title">Messages List</div>
          <CSVLink filename="alert_responses" data={csvData}>
            <WhiteButton
              data-uie-name="do-export-report"
              className="btn btn-small btn-secondary pull-right"
            >
              Export Full Report
            </WhiteButton>
          </CSVLink>
        </Col>
      </Row>
      <Divider />
      <List
        header={<UserStatusInfoHeader onApply={setStatusFilterOptions} />}
        bordered={false}
        dataSource={filteredUserStatuses}
        renderItem={userStatus => (
          <ReportUserStatusInfo
            userStatus={userStatus}
            onEscalate={(alertId, userId) => {
              confirm({
                title: 'Escalate',
                content: (
                  <>{'Are you sure you want to escalate for this user?'}</>
                ),
                async onOk() {
                  try {
                    await escalateForUser(alertId, userId);

                    updateReportData(alertId);
                    updateUserStatusesData(alertId);
                  } catch (e) {
                    console.log(e);
                  }
                },
              });
            }}
          />
        )}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  isLoadingReport: getReport(state).isLoading,
  alert: getReport(state).alert,
  report: getReport(state).report,
  userStatusesHistory: getReport(state).userStatusesHistory,
  appointedContact: getReport(state).appointedContact,
});

export default connect(mapStateToProps, {
  escalateForUser,
  fetchReportData,
  updateReportData,
  updateUserStatusesData,
})(Report);
