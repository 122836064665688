import { createSlice, PayloadAction } from 'redux-starter-kit';

import { AlertResponse } from '../../types';

interface InitialState {
  error: string;
  isLoading: boolean;
  reports: AlertResponse[];
}

const initialState: InitialState = {
  error: '',
  isLoading: false,
  reports: [],
};

const reportsSlice = createSlice({
  slice: 'reports',
  initialState: initialState,
  reducers: {
    fetchReports(state) {
      state.isLoading = true;
    },
    fetchReportsSuccess(state, action: PayloadAction<AlertResponse[]>) {
      state.isLoading = false;
      state.reports = action.payload;
    },
    fetchReportsFail(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    }
  },
});

const { actions, reducer, selectors } = reportsSlice;

export const { fetchReports, fetchReportsFail, fetchReportsSuccess } = actions;
export const { getReports } = selectors;
export default reducer;
