import { FunctionComponent } from 'react';
import styled from '@emotion/styled';

export interface FormItemColumnsProps {
  spaceBetween?: number;
}

const FormItemColumns: FunctionComponent<FormItemColumnsProps> = styled.div<
  FormItemColumnsProps
>(({ spaceBetween }) => ({
  '&': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '.ant-form-item': {
    '&:not(:last-child)': {
      marginRight: spaceBetween || 13,
    },
    width: '100%',
  },
}));

export default FormItemColumns;
